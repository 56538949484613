<template>
  <v-container fluid>
    <v-toolbar rounded class="mb-2">
      <v-toolbar-title class="text-h5" elevation="20">
        Développement
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- ajouter un menu pour mettre mes boutton dedans -->
      <v-menu offset-y left max-width="250" v-if="isEncadrement || canCreateTasks">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list min-width="250px">
          <v-list-item @click="openModalCreate">
            <v-list-item-title class="purple--text text--darken-3"
              >Créer une carte</v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title class="purple--text text--lighten-2"
              >A voir</v-list-item-title
            >
          </v-list-item> -->
        </v-list>
      </v-menu>
    </v-toolbar>

   

    <AllYears
      :taskSupportVision="cards"
      @task-updated="handleTaskUpdate"
      :users="users"
      @card-dropped="onCardDropped"
    />
    <!-- modale de creation -->
    <v-dialog v-model="createDev" max-width="50%">
      <CreateDev @close-create="createDev = false" @refresh="loadData" />
    </v-dialog>

    <!-- modale de liste des demandes -->
    <v-dialog v-model="modalListeDemande" max-width="90%">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Liste des demandes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="modalListeDemande = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- trier par impact desc -->
        <v-data-table
          :headers="headers"
          :items="taskSupportVision"
          :items-per-page="5"
          sort-by="carte.impactType"
          sort-desc
          class="elevation-1"
        >
          <!-- si non assigné afficher non assigné -->
          <template v-slot:item.carte.assignement.assignedTo="{ item }">
            {{
              item.carte.assignement
                ? item.carte.assignement.assignedTo
                : "Non assigné"
            }}
          </template>

          <!-- afficher la duree au bon format avec filtre -->
          <template v-slot:item.carte.assignement.duree="{ item }">
            <p v-if="item.carte.assignement && item.carte.assignement.duree">
              {{ item.carte.assignement.duree | formatDuration }}
            </p>
            <p v-else>
              Non assigné
            </p>
          </template>

          <!-- afficher dans v-chip de couleur les type d impact -->
          <template v-slot:item.carte.impactType="{ item }">
            <v-chip
              :color="getColorByDemande(item.carte.impactType)"
              dark
              small
            >
              {{ item.carte.impactType }}
            </v-chip>
          </template>

          <template v-slot:item.carte.assignement.status="{ item }">
            <v-chip
              v-if="item.carte.assignement && item.carte.assignement.status"
              :color="getColorByStatus(item.carte.assignement.status)"
              dark
              small
            >
              {{ item.carte.assignement.status }}
            </v-chip>
            <v-chip v-else color="black" dark small>
              Aucun
            </v-chip>
          </template>

          <!-- si pas de module afficher aucun -->
          <!-- <template v-slot:item.carte.module="{ item }">
            {{ item.carte.module.length > 0 ? item.carte.module.join() : 'Aucun' }}
          </template> -->

          <!-- si pas de sous module afficher aucun -->
          <template v-slot:item.carte.sous_module="{ item }">
            {{ item.carte.sous_module && formatData(item.carte.sous_module) }}
          </template>

          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <!-- unassign task -->
              <v-btn
                v-if="
                  item.carte.assignement && item.carte.assignement.assignedTo
                "
                icon
                small
                color="primary"
                dark
                @click="unassignTask(item._id)"
              >
                <v-icon>mdi-account-minus</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="warning"
                dark
                @click="openModalUpdate(item._id)"
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                small
                color="error"
                dark
                @click="openModalDelete(item._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <!-- modale de modification -->
    <v-dialog v-model="modaleUpdate" max-width="50%">
      <EditDev @close-edit="modaleUpdate = false" @refresh="loadData" />
    </v-dialog>
    <!-- modale edit avec assignement -->
    <!-- modale estimation durée -->
    <v-dialog
      v-model="estimateDurationDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-alert type="warning" dense prominent border="left">
          <div class="d-flex align-center">
            <strong class="mr-4">Estimer de la durée</strong>
            <div>{{ getTaskTitle }}</div>
          </div>
        </v-alert>

        <v-card-text class="py-0">
          <!-- Formulaire de durée -->
          <v-row class="pa-0"> 
            <v-col cols="6">
              <v-text-field
                v-model.number="estimatedDuration"
                label="Durée"
                type="number"
                :min="0.5"
                :max="24"
                :step="0.5"
                outlined
                dense
                :rules="durationRules"
                hint="Entre 0.5 et 24"
                persistent-hint
              >
                <template v-slot:append>
                  <v-icon color="primary">mdi-clock-outline</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="durationUnit"
                :items="unitItems"
                label="Unité"
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon color="primary">mdi-calendar-clock</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="estimateDurationDialog = false"
            :disabled="savingDuration"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :loading="savingDuration"
            :disabled="!isValidDuration || savingDuration"
            @click="addDurationToTask"
          >
            <v-icon left>mdi-check</v-icon>
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- modale de suppression -->
    <v-dialog v-model="modaleDelete" max-width="30%">
      <DeleteDev
        @close-delete-modal="modaleDelete = false"
        @dev-deleted="loadData"
      />
    </v-dialog>
    <!-- carte details -->
    <v-dialog v-model="modaleDetails" max-width="50%">
      <CarteDetails @close="modaleDetails = false" @refresh="loadData" />
    </v-dialog>

    <!-- Dialog pour le chat -->
    <v-dialog v-model="carteChat" max-width="60%">
      <CarteChat
        @close="carteChat = false"
        :card="selectedCard"
        :users="users"
        :location="location"
        @refresh="loadData"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import UserService from "@/Services/UserService";
import * as Vuex from "vuex";
import moment from "moment";
import BasesService from "@/Services/SupportVision/BasesService";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import AllYears from "./allYears.vue";
import Carte from "../Cartes/carte.vue";
import ParametrageService from "@/Services/SupportVision/ParametrageService";
import UpdateTaskWithAssignment from "./updateTaskWithAssignment.vue";

export default {
  name: "Index",
  components: {
    ClientPickerSelect: () =>
      import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
    CreateDev: () =>
      import("@/Components/Views/SupportVision/Cartes/createDev"),
    Calendar: () =>
      import("@/Components/Views/SupportVision/Developpement/calendar"),
    EditDev: () => import("@/Components/Views/SupportVision/Cartes/editDev"),
    DeleteDev: () =>
      import("@/Components/Views/SupportVision/Developpement/devDelete"),
    CarteDetails: () =>
      import("@/Components/Views/SupportVision/Cartes/carteDetails.vue"),
    AllYears,
    Carte,
    CarteChat: () =>
      import("@/Components/Views/SupportVision/Cartes/carteChat"),
    UpdateTaskWithAssignment,
  },
  data() {
    return {
      createDev: false,
      panelOpen: false,
      devTypeColors: {},
      cards: [],
      users: [],
      usersSelected: [],
      selected: false,
      dateDebut: "",
      dateFin: "",
      selectedEvent: {},
      modalListeDemande: false,
      colorByDevType: null,
      selectedTask: null,
      headers: [
        { text: "Libellé", value: "carte.libelle", width: "15%" },
        {
          text: "Assigné",
          value: "carte.assignement.assignedTo",
          width: "15%",
        },
        { text: "Durée", value: "carte.assignement.duree", width: "15%" },
        { text: "Impact", value: "carte.impactType" },
        {
          text: "Statut",
          value: "carte.assignement.status",
          sortable: false,
          width: "15%",
        },
        { text: "Type de développement", value: "carte.devType", width: "10%" },
        { text: "Origine", value: "carte.originType", width: "10%" },
        { text: "Module", value: "carte.module", width: "10%" },
        { text: "Sous module", value: "carte.sous_module", width: "15%" },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          width: "10%",
        },
      ],
      modaleUpdate: false,
      modaleDelete: false,
      rights: [],
      modaleDetails: false,
      cardShow: {},
      search: "",
      // carte
      assignTaskDialog: false,
      estimateDurationDialog: false,
      datePrevisionnelDialog: false,
      selectedCard: null,
      selectedUser: null,
      assignmentDateDebut: null,
      assignmentDateFin: null,
      estimatedDuration: null,
      timeDebutMenu: false,
      timeFinMenu: false,
      dateDebutMenu: false,
      dateFinMenu: false,
      timeDebut: null,
      timeFin: null,
      durationUnit: "heures",
      previsionnelDate: null,
      carteChat: false,
      location: "",

      savingDuration: false,
      unitItems: [
        { text: "Heures", value: "heures" },
        { text: "Jours", value: "jours" },
      ],
      durationRules: [
        (v) => !!v || "La durée est requise",
        (v) => v >= 0.5 || "La durée minimale est de 0.5 heure",
        (v) => v <= 24 || "La durée maximale est de 24 heures",
      ],
    };
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    formatDuration: function(value) {
      if (value) {
        moment.locale("fr");
        return moment.duration(value).humanize();
      }
    },
  },
  computed: {
    ...Vuex.mapMutations(["setTaskSupportVision", "setTaskId", "setRightsDev"]),
    ...Vuex.mapState(["taskSupportVision", "informations"]),
    ...Vuex.mapGetters([
      "canCreateTasks",
      "getResourceTeam",
    ]),
    getColorByDemande() {
      return (demande) => {
        const colors = {
          "Travaux unique": "#43A047",
          Release: "#1E88E5",
          Urgent: "#E53935",
        };
        return colors[demande] || "#7b4e8e";
      };
    },
    getColorByStatus() {
      return (status) => {
        if (status == "en_cours") {
          return "orange";
        } else if (status == "terminé") {
          return "green";
        } else if (status == "prise_en_compte") {
          return "grey";
        } else if (status == undefined || status == "") {
          return "black";
        }
      };
    },
    // retoune les taches non assigné
    unAssignedTask() {
      if (!this.cards) return [];

      return this.cards.filter((item) => {
        try {
          return (
            !item?.carte?.assignement ||
            item.carte.assignement.assigned === false ||
            !item.carte.assignement.assignedTo
          );
        } catch (error) {
          console.error("Erreur de filtrage pour item:", item);
          return false;
        }
      });
    },
    filteredUnAssignedTask() {
      return (
        this.unAssignedTask?.filter((item) => {
          if (!item?.carte || !item.carte?.libelle) return false;

          let searchTerm = this.search?.toLowerCase() || "";
          // Normalisation du terme de recherche (suppression accents)
          searchTerm = searchTerm
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          try {
            // Fonction helper pour normaliser les chaînes
            const normalize = (str) => {
              return str
                ? str
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                : "";
            };

            // Normalisation de tous les champs de recherche
            const normalizedLibelle = normalize(item.carte.libelle);
            const normalizedModule = normalize(item.carte.module);
            const normalizedSousModule = normalize(item.carte.sous_module);
            const normalizedDevType = normalize(item.carte.devType);
            const normalizedOriginType = normalize(item.carte.originType);
            const normalizedImpactType = normalize(item.carte.impactType);

            return (
              normalizedLibelle.includes(searchTerm) ||
              normalizedModule.includes(searchTerm) ||
              normalizedSousModule.includes(searchTerm) ||
              normalizedDevType.includes(searchTerm) ||
              normalizedOriginType.includes(searchTerm) ||
              normalizedImpactType.includes(searchTerm)
            );
          } catch (error) {
            console.error("Erreur de filtrage pour item:", item, error);
            return false;
          }
        }) || []
      );
    },
    isValidAssignTask() {
      return (
        !!this.usersSelected.length &&
        !!this.dateDebut &&
        !!this.dateFin &&
        !!this.selectedEvent._id
      );
    },
    // isRights() {
    //   let userMail = this.informations.emailAddress;
    //   let callCenterMail = [];
    //   let sysAdminMail = [];
    //   let directionMail = [];
    //   let equipeTechniqueMail = [];
    //   this.rights.map((role) => {
    //     callCenterMail = role.params.callCenterMail;
    //     sysAdminMail = role.params.sysAdminMail;
    //     directionMail = role.params.directionMail;
    //     equipeTechniqueMail = role.params.equipeTechniqueMail;
    //   });
    //   let emailAuthor =
    //     sysAdminMail.includes(userMail) || directionMail.includes(userMail);
    //   // commit les droits dans le store
    //   this.$store.commit("setRightsDev", emailAuthor);
    //   return emailAuthor;
    // },
    isEncadrement() {
      return this.getResourceTeam(this.informations.id) === "Encadrement";
    },
    isValidDuration() {
      return (
        this.estimatedDuration &&
        this.estimatedDuration >= 0.5 &&
        this.estimatedDuration <= 24
      );
    },
    getTaskTitle() {
      return this.selectedTask &&
        this.selectedTask.carte &&
        this.selectedTask.carte.libelle
        ? this.selectedTask.carte.libelle
        : "";
    },
  },
  methods: {
    openModalCreate() {
      this.createDev = true;
    },
    openModalUpdate(id) {
      this.$store.commit("setTaskId", id);
      this.modaleUpdate = true;
    },
    openDetail(id) {
      this.$store.commit("setTaskId", id);
      this.modaleDetails = true;
    },
    closeModaleUpdate() {
      this.modaleUpdate = false;
    },
    openModalDelete(id) {
      this.$store.commit("setTaskId", id);
      this.modaleDelete = true;
    },
    closeModaleDelete() {
      this.modaleDelete = false;
    },
    // panelValue() {
    //   this.panelOpen = !this.panelOpen;
    // },
    // handleSearchFieldClick(e) {
    //   e.stopPropagation();
    // },
    openModalListeDemande() {
      this.modalListeDemande = true;
    },
    resetAssignTask() {
      this.usersSelected = [];
      this.dateDebut = "";
      this.dateFin = "";
    },
    selectCard(item) {
      this.selectedEvent = item;
    },
    toggleCardShow(card) {
      this.$set(this.cardShow, card.carte._id, !this.cardShow[card.carte._id]);
    },
    formatData(data) {
      // si data est un tableau
      if (Array.isArray(data)) {
        return data.join();
      } else {
        return data && data.replace(/[\][\]"]+/g, "");
      }
    },
    async addDurationToTask() {
      try {
        if (!this.selectedTask || !this.estimatedDuration) {
          this.$nError("Veuillez entrer une durée valide");
          return;
        }

        const data = {
          duree: this.estimatedDuration,
          unite: this.durationUnit,
        };

        await DeveloppementService.addDuration(this.selectedTask._id, data);

        // Mettre à jour la tâche localement
        const taskIndex = this.cards.findIndex(
          (t) => t._id === this.selectedTask._id
        );
        if (taskIndex !== -1) {
          this.$set(this.cards[taskIndex].carte, "duree", data);
        }

        this.$nSuccess("Durée ajoutée avec succès");
        this.estimateDurationDialog = false;
        this.estimatedDuration = null;
        this.selectedTask = null;

        // Recharger les données
        await this.loadData();
      } catch (error) {
        console.error("Erreur lors de l'ajout de la durée:", error);
        this.$nError("Erreur lors de l'ajout de la durée");
      }
    },
    // fonction composant avec Carte
    async assignTask() {
      const data = {
        assignement: {
          dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          assignedTo: this.usersSelected,
          dateDebut: this.dateDebut,
          dateFin: this.dateFin,
          event: this.selectedEvent,
          id: this.selectedEvent._id,
          assigned: true,
        },
      };
      const id = this.selectedEvent._id;
      const res = await DeveloppementService.assignTask(id, data);
      this.resetAssignTask();
      this.loadData();
      this.$nInfo("Tâche assignée avec succès");
    },
    // handleDragStart(event, task) {
    //   // Vérifier si la tâche a une durée estimée
    //   console.log(task);

    //   if (!task.carte.duree || task.carte.duree.duree === 0) {
    //     event.preventDefault(); // Empêcher le drag
    //     this.selectedCard = task;
    //     this.selectedTask = task; // Ajouter cette ligne
    //     this.estimateDurationDialog = true;
    //     return;
    //   }
    //   const dragData = {
    //     taskId: task._id,
    //     type: "unassigned-task",
    //   };

    //   event.dataTransfer.setData("text/plain", JSON.stringify(dragData));
    //   event.target.classList.add("dragging");
    // },
    // handleDragEnd(event) {
    //   event.target.classList.remove("dragging");
    // },

    openChat(card) {
      this.selectedCard = card;
      this.carteChat = true;
    },

    async onCardDropped({ resource, date, taskId }) {
      try {
        const task = this.unAssignedTask.find((t) => t._id === taskId);
        if (!task) return;

        const assignmentData = {
          assignement: {
            assigned: true,
            assignedTo: resource.name,
            dateDebut: moment(date)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            dateFin: moment(date)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        };

        await DeveloppementService.assignTask(task._id, assignmentData);
        await this.loadData();
        this.$nInfo("Tâche assignée avec succès");
      } catch (error) {
        console.error("Erreur lors de l'assignation:", error);
        this.$nError("Erreur lors de l'assignation de la tâche");
      }
    },
    async unassignTask(id) {
      const res = await DeveloppementService.unassignTask(id);
      this.loadData();
      this.$nInfo("Tâche désassignée avec succès");
    },
    handleTaskUpdate(updatedTask) {
      this.loadData();
    },
    getColorByDevType(devType) {
      return (this.devTypeColors && this.devTypeColors[devType]) || "#7b4e8e";
    },
    async closeUpdateModal() {
      this.estimateDurationDialog = false;
      this.selectedCard = null;
      this.selectedTask = null;
    },

    async loadData() {
      const res = await DeveloppementService.getDev();
      this.$store.commit("setTaskSupportVision", res.data);
      this.cards = this.$store.state.taskSupportVision;
      await UserService.getUsers().then((res) => {
        this.users = res;
      });
      this.rights = await BasesService.getParams();

      // Charger les couleurs
      const resParams = await ParametrageService.getParametrages();
      const parametrages = resParams.data;

      // Créer un objet qui map les types avec leurs couleurs
      this.devTypeColors = {};
      parametrages.forEach((param) => {
        if (
          param.parametrage &&
          param.parametrage.choix &&
          param.parametrage.couleur
        ) {
          this.devTypeColors[param.parametrage.choix] =
            param.parametrage.couleur;
        }
      });
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style scoped>
.disabled-button {
  pointer-events: none;
  opacity: 0.5;
}
.autocomplete-max-height {
  max-height: 50px;
  overflow-y: auto;
}
.biais-trait {
  position: relative;
}

.biais-trait::after {
  content: "";
  position: absolute;
  bottom: 5px;
  right: 13px; /* Ajustez la position du trait */
  width: 2px; /* Longueur du trait */
  height: 20px;
  background-color: gray; /* Couleur du trait */
  transform: skewX(-50deg); /* Inclinaison du trait */
}
.biais-trait::before {
  content: "";
  position: absolute;
  bottom: 3px;
  right: 10px;
  width: 2px;
  height: 14px;
  background-color: gray;
  transform: skewX(-50deg);
}
.unassigned-tasks-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-content: center;
  gap: 12px;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
}

.task-card {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  width: 250px;
  cursor: move;
  transition: all 0.2s;
}

.task-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-color: #7b1fa2;
}

.task-title {
  font-weight: 500;
  margin: 4px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-details {
  color: #666;
  font-size: 0.85em;
}

.task-card.dragging {
  opacity: 0.5;
  transform: scale(0.95);
}
</style>
