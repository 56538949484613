<template>
  <div class="estimation-cell" :class="bgClass">
    <!-- Résumé compact -->
    <div
      class="estimation-badge"
      @click="toggleExpand"
      :style="{ backgroundColor: getAllColorByTeam }"
    >
      {{ totalDuration }}h
      <span v-if="hasMultipleTasks" class="task-counter"
        >({{ tasks.length }})</span
      >
      <span
        v-if="hasMultipleTasks"
        class="toggle-dot"
        :class="{ 'is-expanded': isExpanded }"
        >•</span
      >
    </div>

    <!-- Liste des tâches -->
    <div v-show="isExpanded" class="tasks-container">
      <v-tooltip v-for="task in tasks" :key="task._id" bottom max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <div
            :style="{ backgroundColor: getColorByTeam(task.carte.impactType) }"
            class="task-row"
            draggable="true"
            @dragstart="handleDragStart($event, task)"
            @click="openTaskDetails(task)"
            v-bind="attrs"
            v-on="on"
          >
            <span class="task-hours">{{ formatDuration(task) }}</span>
            <span class="task-name" :title="task.carte.libelle">{{
              task.carte.libelle
            }}</span>
          </div>
        </template>
        <div class="tooltip-content">
          <div><strong>Libellé:</strong> {{ task.carte.libelle }}</div>
          <div><strong>Module:</strong> {{ task.carte.module }}</div>
          <div><strong>Type:</strong> {{ task.carte.devType }}</div>
          <div><strong>Impact:</strong> {{ task.carte.impactType }}</div>
          <div v-if="task.carte.assignement">
            <strong>Assigné à:</strong>
            {{ task.carte.assignement.assignedTo || "Non assigné" }}
          </div>
          <div v-if="task.carte.duree">
            <strong>Durée estimée:</strong> {{ task.carte.duree.duree }}h
          </div>
          <div v-if="task.carte.assignement && task.carte.assignement.status">
            <strong>Statut:</strong>
            {{ formatStatus(task.carte.assignement.status) }}
          </div>
        </div>
      </v-tooltip>
    </div>

    <!-- Tâche unique -->
    <v-tooltip v-if="!isExpanded && !hasMultipleTasks" bottom max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="single-task"
          draggable="true"
          @dragstart="handleDragStart($event, tasks[0])"
          @click="openTaskDetails(tasks[0])"
          v-bind="attrs"
          v-on="on"
          :style="{ backgroundColor: getAllColorByTeam }"
        >
          {{ tasks[0].carte.libelle }}
        </div>
      </template>
      <div class="tooltip-content">
        <div><strong>Libellé:</strong> {{ tasks[0].carte.libelle }}</div>
        <div><strong>Module:</strong> {{ tasks[0].carte.module }}</div>
        <div><strong>Type:</strong> {{ tasks[0].carte.devType }}</div>
        <div><strong>Impact:</strong> {{ tasks[0].carte.impactType }}</div>
        <div v-if="tasks[0].carte.assignement">
          <strong>Assigné à:</strong>
          {{ tasks[0].carte.assignement.assignedTo || "Non assigné" }}
        </div>
        <div v-if="tasks[0].carte.duree">
          <strong>Durée estimée:</strong> {{ formatDurationToHourMinutes(tasks[0].carte.duree.duree) }}
        </div>
        <div
          v-if="tasks[0].carte.assignement && tasks[0].carte.assignement.status"
        >
          <strong>Statut:</strong>
          {{ formatStatus(tasks[0].carte.assignement.status) }}
        </div>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "EstimationTasksDisplay",

  props: {
    tasks: {
      type: Array,
      required: true,
      default: function() {
        return [];
      },
    },
  },

  data: function() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    totalDuration: function() {
      return this.tasks.reduce(function(total, task) {
        return total + (task.carte.duree ? task.carte.duree.duree : 0);
      }, 0);
    },

    hasMultipleTasks: function() {
      return this.tasks.length > 1;
    },

    bgClass: function() {
      let hours = this.totalDuration;
      if (hours <= 3) return "low-load";
      if (hours <= 5) return "medium-load";
      if (hours <= 7) return "high-load";
      return "critical-load";
    },

    getColorByTeam() {
      return (devType) => {
        const color = this.$store.getters.getColorByDevType(devType);
        if (!color && color !== "#7b4e8e") {
          this.$reloadColors();
        }
        return color || "#7b4e8e";
      };
    },
    getAllColorByTeam() {
      // Si pas de tâches, retourner la couleur par défaut
      if (!this.tasks.length) {
        return "#7b4e8e";
      }

      // Récupérer les couleurs de toutes les tâches
      const colors = this.tasks.map((task) =>
        this.getColorByTeam(task.carte.impactType)
      );

      // Retourner la première couleur
      return colors[0];
    },
  },

  methods: {
    toggleExpand: function() {
      if (this.hasMultipleTasks) {
        this.isExpanded = !this.isExpanded;
      }
    },

    openTaskDetails(task) {
      this.$emit("task-click", task);
    },

    handleDragStart: function(event, task) {
      this.$emit("task-drag-start", event, task);
    },

    formatDuration: function(task) {
      return task.carte.duree ? task.carte.duree.duree + "h" : "0h";
    },

    formatDurationToHourMinutes(duration) {
      if (!duration && duration !== 0) return "";

      const hours = Math.floor(duration);
      const minutes = Math.round((duration - hours) * 60);

      if (minutes === 0) {
        return `${hours}h`;
      } else if (hours === 0) {
        return `${minutes}min`;
      }

      return `${hours}h${minutes}`;
    },

    formatStatus: function(status) {
      const statusMap = {
        en_cours: "En cours",
        terminé: "Terminé",
        prise_en_compte: "Prise en compte",
      };
      return statusMap[status] || status;
    },
  },
};
</script>

<style scoped>
.estimation-cell {
  padding: 2px;
  font-size: 11px;
  min-height: 22px;
  width: 100%;
}

/* Ajout des styles pour le tooltip */
.tooltip-content {
  font-size: 12px;
  line-height: 1.4;
}

.tooltip-content > div {
  margin-bottom: 4px;
}

.tooltip-content strong {
  color: rgba(255, 255, 255, 0.9);
}

/* Reste des styles inchangé */
.low-load {
  background-color: rgba(33, 150, 243, 0.1);
}
.medium-load {
  background-color: rgba(33, 150, 243, 0.2);
}
.high-load {
  background-color: rgba(33, 150, 243, 0.3);
}
.critical-load {
  background-color: rgba(33, 150, 243, 0.4);
}

.estimation-badge {
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.task-counter {
  margin-left: 2px;
  opacity: 0.7;
  font-size: 10px;
}

.toggle-dot {
  margin-left: 2px;
  font-size: 12px;
  line-height: 8px;
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.toggle-dot.is-expanded {
  transform: rotate(90deg);
}

.tasks-container {
  margin-top: 2px;
  max-height: 120px;
  overflow-y: auto;
}

.task-row {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  margin: 1px 0;
  padding: 2px 3px;
  border-radius: 2px;
  cursor: grab;
}

.task-hours {
  flex: 0 0 auto;
  font-size: 10px;
  opacity: 0.8;
  margin-right: 3px;
  min-width: 16px;
}

.task-name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single-task {
  background: rgba(255, 255, 255, 0.9);
  padding: 2px 3px;
  margin-top: 1px;
  border-radius: 2px;
  cursor: grab;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tasks-container::-webkit-scrollbar {
  width: 3px;
}

.tasks-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.tasks-container::-webkit-scrollbar-track {
  background: transparent;
}
</style>
