<template>
  <div class="d-flex elevation-8">
    <v-navigation-drawer
      class="height-calcul"
      permanent
      v-model="drawer"
      :mini-variant.sync="isMini"
    >
      <div
        class="resource-names"
        ref="resourceNames"
        @scroll="onResourceNamesScroll"
      >
        <div class="resource-menu">
          <div class="d-flex flex-column" style="width: 100%;">
            <!-- Header -->
            <header class="custom-ressource-header pr-0">
              <nav
                class="d-flex align-center justify-space-between"
                style="width: 100%;"
              >
                <div class="active" style="font-size: 14px;" v-if="!isMini">
                  Ressources
                </div>
                <span class="active">
                  <v-btn icon @click.stop="isMini = !isMini">
                    <v-icon color="primary" v-if="!isMini"
                      >mdi-chevron-left</v-icon
                    >
                    <v-icon
                      color="primary"
                      style="position: absolute; right: 0; left: 0;"
                      v-else
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                </span>
              </nav>
            </header>

            <!-- Menu container -->
            <div class="menu-container" :class="{ 'menu-mini': isMini }"
              :style="{ height: getMenuHeightByTeam }"
            >
              <template v-if="!isMini">
                <!-- Resource filter -->
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-y
                  v-model="menu"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="menu"
                      @click="handleClick"
                      color="primary"
                    >
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-text-field
                        ref="searchInput"
                        v-model="searchResource"
                        append-icon="mdi-magnify"
                        label="Filtrer par ressource"
                        hide-details
                        solo
                        dense
                        @click:append="searchResourceFunc"
                        @keyup.enter="searchResourceFunc"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </v-menu>

                <MonthSelector @month-selected="handleMonthSelection" />

                <!-- Team filter -->
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" color="primary">
                      <v-icon>mdi-account-group</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-list dense>
                        <v-list-item
                          v-for="item in teamItems"
                          :key="item.value"
                          @click="selectedTeam = item.value"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              :class="{
                                'primary--text': selectedTeam === item.value,
                              }"
                            >
                              {{ item.text }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="selectedTeam === item.value"
                          >
                            <v-icon color="primary">mdi-check</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>

              <!-- Mini version -->
              <template v-else>
                <div class="mini-buttons"
                :style="{ top: getStyleBtnIsMini }"
                >
                  <v-btn icon small color="primary" class="mb-2"
                  >
                    <!-- icon menu -->
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                  <!-- <v-btn icon x-small color="primary" class="mb-2">
                    <v-icon small>mdi-account</v-icon>
                  </v-btn>
                  <v-btn icon x-small color="primary" class="mb-2">
                    <v-icon small>mdi-calendar</v-icon>
                  </v-btn>
                  <v-btn icon x-small color="primary" class="mb-2">
                    <v-icon small>mdi-account-group</v-icon>
                  </v-btn> -->
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- Stats section -->
        <div
          v-if="isEncadrement"
          class="resource-name info-row py-0 px-0"
          :class="{ 'ressource-mini': isMini }"
        >
          <template v-if="!isMini">
            <v-sheet class="fill-height" elevation="2" width="100%">
              <div class="d-flex flex-column">
                <!-- Header -->
                <div class=" font-weight-medium text-center pt-4">
                  <v-icon
                    color="primary"
                    style="position: absolute;
                  left: 11px; top: 15px;
                  "
                    >mdi-calendar-clock</v-icon
                  >
                  <p class="pb-1 ma-0">Informations</p>
                </div>
                <!-- Stats -->
                <v-list dense class="" style="max-height: 120px">
                  <v-divider></v-divider>
                  <v-list-item class="px-1">
                    <v-list-item-icon class="mx-2">
                      <v-icon color="primary">mdi-clock-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ totalHours }}h de travail</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-1">
                    <v-list-item-icon class="mx-2">
                      <v-icon color="primary">mdi-calendar-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ estimatedTasks }} tâches
                        planifiées</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-1">
                    <v-list-item-icon class="mx-2">
                      <v-icon color="warning">mdi-calendar-question</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="warning--text">
                      <v-list-item-title>
                        {{ getTasksWithoutDate.length }}
                        {{
                          getTasksWithoutDate.length > 1
                            ? "non estimées"
                            : "non estimée"
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <!-- Unassigned Tasks -->
                <v-sheet
                  color="grey lighten-4"
                  rounded
                  class="mt-2 flex-grow-1"
                  style="max-height: 130px"
                  @dragover.prevent="onUnassignedDragOver"
                  @dragenter="onUnassignedDragEnter"
                  @dragleave="onUnassignedDragLeave"
                  @drop="onUnassignedDrop"
                >
                  <div class="px-2 pt-1">
                    <!-- <div v-if="getTasksWithoutDate.length >= 1" class="text-subtitle-2">
             <v-icon color="warning" small class="mr-1">mdi-calendar-remove</v-icon>
             {{ getTasksWithoutDate.length }} sans prévisionnelles
           </div> -->

                    <draggable
                      v-model="getTasksWithoutDate"
                      :options="{ group: 'tasks' }"
                      class="tasks-container"
                    >
                      <v-hover
                        v-for="task in getTasksWithoutDate"
                        :key="task._id"
                        v-slot="{ hover }"
                      >
                        <v-sheet
                          :elevation="hover ? 4 : 1"
                          :class="{ 'on-hover': hover }"
                          class="unassigned-task pa-1"
                          :style="{
                            backgroundColor: getColorByTeam(
                              task.carte.impactType
                            ),
                          }"
                          draggable
                          @dragstart="handleTaskDragStart($event, task)"
                          @mouseenter="showTooltipWithoutDate(task, $event)"
                          @mouseleave="hideTooltip"
                          @click.stop="openTaskDetails(task)"
                        >
                          <div
                            class="d-flex align-center justify-space-between"
                            style="width: 100%;"
                          >
                            <v-chip
                              class="task-title caption   white--text"
                              x-small
                              label
                              :color="getColorByTeam(task.carte.impactType)"
                            >
                              {{ task.carte.libelle }}
                            </v-chip>
                            <v-chip
                              class="task-title caption text-truncate white--text"
                              x-small
                              label
                              :color="getColorByTeam(task.carte.impactType)"
                            >
                              {{ formatedCreatedAt(task.createdAt) }}
                            </v-chip>
                            <v-chip
                              v-if="task.carte.duree"
                              x-small
                              label
                              color="primary"
                              class="ml-2 white--text caption text-truncate"
                            >
                              {{
                                formatDurationToHourMinutes(
                                  task.carte.duree.duree
                                )
                              }}
                            </v-chip>
                          </div>
                        </v-sheet>
                      </v-hover>
                    </draggable>
                    <div
                      v-if="getTasksWithoutDate.length === 0"
                      class="d-flex align-center justify-center text-center text-subtitle-2 grey--text"
                      style="height: 100px;"
                    >
                      <p>Aucune tâche sans date</p>
                    </div>
                  </div>
                </v-sheet>

                <v-btn
                  text
                  small
                  block
                  color="primary"
                  class="mt-2 text-decoration-underline"
                  @click="openInNewWindow"
                  :style="getStyleListeWihtoutDate"
                >
                  <span>Voir les cartes</span>
                  <v-icon small class="ml-1 pb-1">mdi-arrow-top-right</v-icon>
                </v-btn>
              </div>
            </v-sheet>
          </template>

          <!-- Mini version -->
          <div v-else class="mini-stats-container info-title-mini">
            <div class="mini-stat">
              <v-icon small color="primary">mdi-clock-outline</v-icon>
              <span class="stat-text">{{
                formatDurationToHourMinutes(totalHours)
              }}</span>
            </div>
            <div class="mini-stat">
              <v-icon small color="primary">mdi-calendar-check</v-icon>
              <span class="stat-text">{{ estimatedTasks }}</span>
            </div>
            <div class="mini-stat">
              <v-icon small color="warning">mdi-calendar-question</v-icon>
              <span class="stat-text">{{ getTasksWithoutDate.length }}</span>
            </div>
            <v-btn
              icon
              small
              color="primary"
              class="open-btn"
              @click="openInNewWindow"
            >
              <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Resource list -->
        <div
          v-for="resource in filteredResources"
          :key="'resource-name-' + resource.id"
          class="resource-name"
          :class="{
            'ressource-mini': isMini,
            pinned: resource.id === pinnedResourceId,
          }"
          @click="openCalendarForResource(resource)"
        >
          <div class="resource-content">
            <div
              class="avatar"
              :class="{ 'avatar-mini': isMini }"
              :style="{ backgroundColor: getAvatarColor(resource) }"
            >
              {{ resource.initials }}
            </div>
            <div class="name-role" :style="isMini ? 'display: none' : ''">
              <div class="name">{{ resource.name }}</div>
            </div>
            <v-btn
              icon
              x-small
              class="pin-button"
              :class="{
                pinned: resource.id === pinnedResourceId,
                'show-on-hover': resource.id !== pinnedResourceId,
              }"
              @click.stop="togglePinResource(resource.id)"
            >
              <v-icon class="pin-custom">
                {{
                  resource.id === pinnedResourceId
                    ? "mdi-pin"
                    : "mdi-pin-outline"
                }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- Calendar section -->
    <div
      class="calendrier-ressources flex-grow-1"
      ref="calendrierRessources"
      @click="handleGlobalClick"
    >
      <header class="custom-header">
        <nav>
          <span class="active">Planning</span>
        </nav>
        <div class="controls" v-if="canViewPlanning">
          <div class="view-selector">
            <span class="mr-2">Vues</span>
            <select v-model="currentView" style="cursor: pointer;">
              <option value="month">Mois</option>
              <option value="week">Semaines</option>
              <option value="day">Jour</option>
            </select>
          </div>
          <div class="zoom-controls">
            <v-btn icon small @click="decreaseZoom">
              <v-icon color="white">mdi-minus</v-icon>
            </v-btn>

            <v-slider
              v-model="zoomLevel"
              min="0.5"
              max="2.5"
              step="0.1"
              class="zoom-slider"
              @change="handleZoomChange"
            ></v-slider>

            <v-btn icon small @click="increaseZoom">
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </div>
          <button @click="goToToday">Aujourd'hui</button>
          <button @click="changeDate(-1)">&lt;</button>
          <button @click="changeDate(1)">&gt;</button>
        </div>
      </header>

      <!-- Calendar grid -->
      <div class="calendar-container">
        <div class="calendar-grid">
          <div class="grid-header">
            <div class="months-header">
              <div
                v-for="(month, monthIndex) in visibleMonths"
                :key="'month-' + monthIndex"
                class="month-header"
              >
                {{ formatMonth(month) }}
              </div>
            </div>
            <div class="days-header">
              <div
                v-for="(day, index) in flattenedDays"
                :key="'day-header-' + index"
                class="day-header"
                :class="{
                  today: isToday(day.date),
                  weekend: isWeekend(day.date),
                }"
              >
                <span v-if="index % 7 === 0" class="week-number"
                  >W{{ getWeekNumber(day.date) }}</span
                >
                <span class="day-name">{{ formatDay(day.date, "ddd") }}</span>
                <span class="day-number">{{ formatDay(day.date, "D") }}</span>
              </div>
            </div>
          </div>

          <!-- Resources grid -->
          <div
            class="resources-grid"
            ref="resourcesGrid"
            @scroll="onResourcesGridScroll"
          >
            <!-- Estimated tasks timeline -->
            <div
              class="resource-row info-timeline"
              v-if="isEncadrement"
              @dragover.prevent="onTimelineDragOver($event)"
              @drop="onTimelineDrop($event)"
              @dragenter="onTimelineDragEnter($event)"
              @dragleave="onTimelineDragLeave($event)"
            >
              <div
                v-for="(day, dayIndex) in flattenedDays"
                :key="'info-day-' + dayIndex"
                class="day-cell"
                :class="{
                  weekend: isWeekend(day.date),
                  today: isToday(day.date),
                  past: isPast(day.date),
                }"
              >
                <div class="estimation-info">
                  <EstimationTasksDisplay
                    v-if="getTasksForDay(day.date).length && canCreateTasks"
                    :tasks="getTasksForDay(day.date)"
                    @task-drag-start="handleTaskDragStart"
                    @task-click="openTaskDetails"
                  />
                </div>
              </div>
            </div>

            <!-- Resource rows -->
            <div
              v-for="resource in filteredResources"
              :key="'resource-' + resource.id"
              class="resource-row"
              :data-resource-id="resource.id"
              :style="{ height: calculateRowHeight(resource) + 'px' }"
            >
              <!-- Cellules des jours -->
              <div
                v-for="(day, dayIndex) in flattenedDays"
                :key="'day-' + resource.id + '-' + dayIndex"
                class="day-cell"
                :class="getDayCellClasses(day.date, resource.id)"
                @dragover.prevent="
                  canDrop(resource.id) ? onDragOver($event) : null
                "
                @drop="
                  canDrop(resource.id)
                    ? onDrop($event, resource, day.date)
                    : null
                "
                @dragenter="
                  canDrop(resource.id) ? onDragEnter($event, day.date) : null
                "
                @dragleave="canDrop(resource.id) ? onDragLeave($event) : null"
                @dblclick="
                  canDoubleClick(resource.id)
                    ? onCellDoubleClick(resource, day.date, $event)
                    : null
                "
              >
                <div class="day-cell-content">
                  <div class="resource-events-container">
                    <!-- Événements -->
                    <div
                      v-for="event in getDayEvents(resource, day.date)"
                      :key="getEventKey(event) + '-' + day.date"
                      class="event"
                      :class="getEventClasses(event, resource.id)"
                      @click.stop="openTaskDetails(event)"
                      :style="getEventStyleWithStack(event, resource, day.date)"
                      :draggable="canDragEvent(event)"
                      @dragstart="onDragStart($event, event)"
                      @dragend="onDragEnd($event)"
                      @mouseenter="showTooltip(event, $event)"
                      @mouseleave="hideTooltip"
                      @contextmenu.prevent="
                        canRightClick(event._id, resource.id)
                          ? editTask(event)
                          : null
                      "
                    >
                      <span class="event-title">{{ event.carte.libelle }}</span>

                      <!-- Indicateur de droits -->
                      <v-icon
                        v-if="!canRightClick(event, resource.id)"
                        small
                        class="lock-icon"
                        color="grey"
                        title="Vous n'avez pas les droits d'éditer cette tâche"
                      >
                        mdi-lock
                      </v-icon>
                    </div>
                  </div>
                </div>

                <!-- Jauge de charge -->
                <div
                  v-if="
                    canViewPlanning &&
                      getDailyWorkload(resource, day.date).totalHours > 0
                  "
                  class="workload-gauge"
                  :class="[
                    getWorkloadClass(
                      getDailyWorkload(resource, day.date).totalHours,
                      getDailyWorkload(resource, day.date).limit
                    ),
                    { disabled: !canDrop(resource.id) },
                  ]"
                >
                  <div
                    class="gauge-bar"
                    :style="{
                      width:
                        getWorkloadPercentage(
                          getDailyWorkload(resource, day.date).totalHours,
                          getDailyWorkload(resource, day.date).limit
                        ) + '%',
                    }"
                  ></div>
                  <div class="gauge-text">
                    {{ getDailyWorkload(resource, day.date).totalHours }}/{{
                      getDailyWorkload(resource, day.date).limit
                    }}h
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="tooltipVisible"
      class="tooltip"
      :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
    >
      <div>
        <strong>{{ tooltipEvent.carte.libelle }}</strong>
      </div>
      <div>Type: {{ tooltipEvent.carte.impactType }}</div>

      <!-- Vérifier l'existence de duree -->
      <div
        v-if="
          tooltipEvent.carte &&
            tooltipEvent.carte.duree &&
            tooltipEvent.carte.duree.duree
        "
      >
        Durée: {{ formatDurationToHourMinutes(tooltipEvent.carte.duree.duree) }}
      </div>
      <!-- Vérifier l'existence de assignement -->
      <template v-if="tooltipEvent.carte.assignement">
        <div v-if="tooltipEvent.carte.assignement.dateDebut">
          Début: {{ formatDate(tooltipEvent.carte.assignement.dateDebut) }}
        </div>
        <div v-if="tooltipEvent.carte.assignement.dateFin">
          Fin: {{ formatDate(tooltipEvent.carte.assignement.dateFin) }}
        </div>
      </template>

      <!-- Origine si elle existe -->
      <div v-if="tooltipEvent.carte.originType">
        Origine: {{ tooltipEvent.carte.originType }}
      </div>
    </div>

    <!-- Modale pour la carte -->
    <v-dialog v-model="showTaskDetails" max-width="400">
      <Carte
        v-if="showTaskDetails"
        :card="selectedTask"
        :is-termine="false"
        :dev-type-colors="devTypeColors"
        :is-from-all-year="true"
        @close="showTaskDetails = false"
      />
    </v-dialog>
    <!-- Modal d'alerte de dépassement -->
    <v-dialog v-model="showLimitAlert" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Dépassement de la limite quotidienne</v-card-title
        >
        <v-card-text>
          <p>
            Cette assignation dépasserait la limite quotidienne de
            {{ ressourceLimite }} heures.
          </p>
          <p>Heures déjà planifiées: {{ heuresPlanifiees }}h</p>
          <p>Heures de la nouvelle tâche: {{ nouvellesHeures }}h</p>
          <p>Total: {{ heuresPlanifiees + nouvellesHeures }}h</p>

          <v-expansion-panels v-if="datesSuggestions.length">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Voir les dates disponibles suggérées
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list dense>
                  <v-list-item
                    v-for="(suggestion, index) in datesSuggestions"
                    :key="index"
                    @click="plannifToDate(suggestion.date)"
                  >
                    <v-list-item-content>
                      {{ suggestion.formattedDate }} - Charge:
                      {{ suggestion.charge }}h
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="autoriserDepassement">
            Autoriser le dépassement
          </v-btn>
          <v-btn color="primary" text @click="showLimitAlert = false">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal pour la création de tâche -->
    <v-dialog v-model="showCreateTaskModal" max-width="800px" persistent>
      <CreateDevWithAssignement
        v-if="showCreateTaskModal"
        :preselectedUser="selectedResourceForTask"
        :preselectedDateRange="selectedDateRange"
        @task-created="onTaskCreated"
        @close="closeCreateModale"
      />
    </v-dialog>

    <!-- Modal pour la modification de tâche -->
    <v-dialog v-model="showTaskModal" max-width="800px">
      <UpdateTaskWithAssignment
        v-if="showTaskModal"
        :task="taskEnCours"
        @task-updated="onTaskUpdated"
        @close="showTaskModal = false"
      />
    </v-dialog>

    <!-- modale calendrier individuel -->
    <v-dialog
      v-model="showResourceCalendar"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <Calendar
        v-if="showResourceCalendar"
        :resource-id="selectedResourceId"
        :resource-name="selectedResourceName"
        :events="getCurrentResourceEvents"
        :isEncadrement="isEncadrement"
        @close="showResourceCalendar = false"
        @create-event="onTaskCreated"
        @update-event="onTaskUpdated"
      />
    </v-dialog>

    <v-dialog v-model="showWeekendAlert" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-icon left color="warning">mdi-calendar-alert</v-icon>
          Jours non ouvrables détectés
        </v-card-title>

        <v-card-text>
          <p>
            La période sélectionnée contient {{ weekendDates.length }} jour(s)
            non ouvrable(s) :
          </p>
          <ul class="mt-2">
            <li v-for="date in weekendDates" :key="date" class="mb-1">
              {{ date }}
            </li>
          </ul>

          <v-radio-group v-model="weekendChoice" class="mt-4">
            <v-radio
              value="skip"
              label="Ne pas comptabiliser les jours non ouvrables (ajuster automatiquement les dates)"
            ></v-radio>
            <v-radio
              value="include"
              label="Inclure les jours non ouvrables dans la planification"
            ></v-radio>
            <v-radio
              value="change"
              label="Sélectionner d'autres dates"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleWeekendChoice">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/fr";
import draggable from "vuedraggable";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import CreateDevWithAssignement from "./createDevWithAssignement.vue";
import UserService from "@/Services/UserService";
import ClientService from "@/Services/SupportVision/ClientService";
import BasesService from "@/Services/SupportVision/BasesService";
import { mapState, mapMutations, mapGetters } from "vuex";
import MonthSelector from "./monthSelector.vue";
import debounce from "lodash/debounce";
import UpdateTaskWithAssignment from "./updateTaskWithAssignment.vue";
import EstimationTasksDisplay from "./EstimationTasksDisplay.vue";
import ParametrageService from "@/Services/SupportVision/ParametrageService";
import DroitService from "@/Services/SupportVision/DroitService";
import { filter } from "lodash";

moment.locale("fr");

export default {
  name: "PlanningRessources",
  components: {
    draggable,
    CreateDevWithAssignement,
    MonthSelector,
    UpdateTaskWithAssignment,
    EstimationTasksDisplay,
    Calendar: () =>
      import("@/Components/Views/SupportVision/Developpement/calendar"),
    Carte: () => import("@/Components/Views/SupportVision/Cartes/carte"),
  },
  props: {
    taskSupportVision: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      showWeekendAlert: false,
      weekendChoice: "skip", // 'skip', 'include', 'change'
      weekendDates: [], // Pour stocker les dates de weekend détectées
      // Garder temporairement les dates sélectionnées
      tempDateRange: null,
      tempSelectedResource: null,
      showTaskDetails: false,
      selectedTask: null,
      zoomLevel: parseFloat(localStorage.getItem("calendarZoomLevel")) || 1,
      baseRowHeight: 80,
      baseAvatarSize: 36,
      pinnedResourceId: null,
      resourceOrder: [],
      isMini: true,
      drawer: true,
      currentDate: new Date(),
      estimatedDatesSectionHeight: 200,
      visibleWeeks: [],
      visibleMonths: [],
      isScrolling: false,
      isDragging: false,
      tooltipEvent: null,
      tooltipVisible: false,
      tooltipX: 0,
      tooltipY: 0,
      dragEndDate: null,
      currentView: localStorage.getItem("calendarView") || "month",
      selectedProject: "all",
      resourceView: "people",
      selectionStart: null,
      selectionEnd: null,
      selectedResource: null,
      showTaskModal: false,
      showCreateTaskModal: false,
      selectedDateRange: null,
      selectedResourceForTask: null,
      searchResource: "",
      isSelecting: false,
      devTypeColors: {},
      selectionStyle: {
        position: "absolute",
        backgroundColor: "rgba(123, 31, 162, 0.2)", // Couleur violette semi-transparente
        border: "1px solid rgb(123, 31, 162)",
        pointerEvents: "none",
        zIndex: 1,
      },
      selectionElement: null,
      showLimitAlert: false,
      taskEnCours: null,
      dateDestination: null,
      ressourceDestination: null,
      ressourceLimite: 7,
      heuresPlanifiees: 0,
      nouvellesHeures: 0,
      datesSuggestions: [],
      workloadCache: {},
      eventsCache: {},
      updateScheduled: false,
      dragCache: null,
      selectedTeam: "all",
      teamOptions: [],
      teamRoleData: null,
      showResourceCalendar: false,
      selectedResourceId: null,
      selectedResourceName: "",
    };
  },
  computed: {
    ...mapState([
      "droitSupportVision",
      "informations",
      "basesParams",
      "userRights",
      "teams",
      "teamsMap",
    ]),
    ...mapGetters([
      "canViewPlanning",
      "canCreateTasks",
      "canDoubleClick",
      "canDragStart",
      "canDrop",
      "canRightClick",
      "canEditDuration",
      "canViewDetails",
      "canModifyResource",
      "getResourceTeam",
    ]),
    getCurrentResourceEvents() {
      if (!this.selectedResource) return [];
      return this.getEventsForResource(this.selectedResource);
    },
    isEncadrement() {
      return this.getResourceTeam(this.informations.id) === "Encadrement";
    },
    getMenuHeightByTeam() {   
      switch (this.getResourceTeam(this.informations.id)) {
        case "Développeur":
          return '95px !important';
        case "Projet":
          return '95px !important';
        default:
          return 0;
      }
    },
    getStyleBtnIsMini() {
      switch (this.getResourceTeam(this.informations.id)) {
        case "Encadrement":
          return '60px !important';
        case "Développeur":
          return '85px !important';
        case "Projet":
          return '85px !important';
        default:
          return 0;
      }
    },
    getColorByTeam() {
      return (devType) => {
        const color = this.$store.getters.getColorByDevType(devType);
        // Si pas de couleur, on force le rechargement
        if (!color && color !== "#7b4e8e") {
          this.$reloadColors();
        }
        return color || "#7b4e8e";
      };
    },
    getStyleListeWihtoutDate() {
      switch (this.getTasksWithoutDate.length) {
        case 0:
          return { "margin-top": "14px !important" };
        case 1:
          return { "margin-top": "40px !important" };
        case 2:
          return { "margin-top": "20px !important" };
        case 3:
          return { "margin-top": "6px !important" };
        case 4:
          return { "margin-top": "6px !important" };
        default:
          return { "margin-top": "0" };
      }
    },
    // Ressources filtrées avec les droits
    filteredResources() {
      if (!this.resources || !this.userRights) return [];

      let filtered = this.resources;
      const userId = this.informations.id;
      const userTeamRole = this.teamsMap[userId];

      if (!userTeamRole) return [];

      // 1. Filtrage selon les droits
      switch (userTeamRole.team) {
        case "Encadrement": {
          if (userTeamRole.roles.planning.scope == "all") {
            // Ne pas filtrer - voir tous les plannings
            break;
          } else if (userTeamRole.roles.planning.scope === "team") {
            const userTeam = this.teamsMap[userId]?.team;
            filtered = filtered.filter((resource) => {
              const resourceTeamRole = this.teamsMap[resource.id];
              return resourceTeamRole?.team === userTeam;
            });
          } else if (userTeamRole.roles.planning.scope == "self") {
            filtered = filtered.filter((resource) => resource.id === userId);
          }
          break;
        }

        case "Développeur": {
          if (userTeamRole.roles.planning.scope == "all") {
            break;
          } else if (userTeamRole.roles.planning.scope === "team") {
            const userTeam = this.teamsMap[userId]?.team;
            filtered = filtered.filter((resource) => {
              const resourceTeamRole = this.teamsMap[resource.id];
              return resourceTeamRole?.team === userTeam;
            });
          } else if (userTeamRole.roles.planning.scope == "self") {
            filtered = filtered.filter((resource) => resource.id === userId);
          }
          break;
        }

        case "Projet": {
          if (userTeamRole.roles.planning.scope == "all") {
            break;
          } else if (userTeamRole.roles.planning.scope === "team") {
            const userTeam = this.teamsMap[userId]?.team;
            filtered = filtered.filter((resource) => {
              const resourceTeamRole = this.teamsMap[resource.id];
              return resourceTeamRole?.team === userTeam;
            });
          } else if (userTeamRole.roles.planning.scope == "self") {
            filtered = filtered.filter((resource) => resource.id === userId);
          }
          break;
        }

        default:
          filtered = filtered.filter((resource) => resource.id === userId);
      }

      // 2. Filtre de recherche
      if (this.searchResource) {
        const searchTerm = this.searchResource.toLowerCase();
        filtered = filtered.filter((resource) =>
          resource.name.toLowerCase().includes(searchTerm)
        );
      }

      // 3. Filtre par équipe sélectionnée
      if (this.selectedTeam && this.selectedTeam !== "all") {
        filtered = filtered.filter((resource) => {
          const resourceTeamRole = this.teamsMap[resource.id];
          return resourceTeamRole?.team === this.selectedTeam;
        });
      }

      if (this.pinnedResourceId) {
        const pinnedResource = filtered.find(
          (r) => r.id === this.pinnedResourceId
        );
        if (pinnedResource) {
          filtered = [
            pinnedResource,
            ...filtered.filter((r) => r.id !== this.pinnedResourceId),
          ];
        }
      }

      return filtered;
    },

    // Ajoutez cette nouvelle propriété computed
    highlightedCells() {
      return this.pinnedResourceId
        ? this.getDayIndexesForResource(this.pinnedResourceId)
        : [];
    },
    userTeamRights: {
      get() {
        return {
          roles: this.teamRoleData?.roles || {
            planning: {
              writeOwnPlanning: false,
              viewTeamPlanning: false,
              writeTeamPlanning: false,
            },
            tasks: {
              editOthersTasks: false,
              viewAllTeams: false,
              writeTeamTasks: false,
            },
          },
          team: this.teamRoleData?.team || null,
        };
      },
      set(newValue) {
        this.teamRoleData = newValue;
      },
    },

    hasTeamAccess() {
      return this.userTeamRights.team !== null;
    },
    resources() {
      return this.users.map((user) => {
        const firstname = user.firstname ?? "";
        const lastname = user.lastname ?? "";

        return {
          id: user.id,
          name: `${firstname} ${lastname}`.trim(),
          initials: `${firstname[0] ?? ""}${lastname[0] ?? ""}` || "-",
        };
      });
    },
    getTasksWithoutDate: {
      get() {
        return this.taskSupportVision.filter((task) => {
          if (!task?.carte) return false;

          // Une tâche est considérée sans date si :
          // - Elle n'a pas de date prévisionnelle
          // - Elle n'est pas assignée
          // - ET si elle a un assignement, il ne doit pas avoir de dates
          return (
            !task.carte.datePrevisionnel?.previsionDate &&
            !task.carte.assignement?.assigned &&
            !task.carte.assignement?.dateDebut &&
            !task.carte.assignement?.dateFin
          );
        });
      },
      set(newValue) {
        // Ne rien faire
      },
    },
    totalHours() {
      const taskHours = this.taskSupportVision.reduce((total, task) => {
        if (task.carte?.duree?.duree) {
          return total + task.carte.duree.duree;
        }
        return total;
      }, 0);
      return Math.round(taskHours * 10) / 10; // Arrondi à 1 décimale
    },

    estimatedTasks() {
      return this.taskSupportVision.filter((task) => {
        return task.carte?.assignement?.assigned === true;
      }).length;
    },

    // Modifier aussi getTotalEstimations pour correspondre à la structure
    getTotalEstimations() {
      const estimatedTasks = this.taskSupportVision.filter((task) => {
        return (
          task.carte?.duree?.duree && task.carte?.assignement?.assigned === true
        );
      });

      const totalHours = estimatedTasks.reduce((total, task) => {
        return total + (task.carte.duree.duree || 0);
      }, 0);

      return `Total: ${totalHours}h\n${estimatedTasks.length} tâches estimées`;
    },

    flattenedDays() {
      return this.visibleWeeks.flat();
    },
    teamItems() {
      // Vérifier si les équipes sont chargées
      if (!this.teams?.length) {
        console.warn("Teams not loaded yet");
        return [{ text: "Toutes les équipes", value: "all" }];
      }

      // Option par défaut
      const options = [{ text: "Toutes les équipes", value: "all" }];

      try {
        // Extraire les équipes uniques
        const uniqueTeams = [
          ...new Set(
            this.teams
              .filter((t) => t.teamRole?.team) // Filtrer les entrées valides
              .map((t) => t.teamRole.team) // Extraire le nom d'équipe
              .sort() // Trier alphabétiquement
          ),
        ];

        // Filtrer selon les droits de l'utilisateur
        const filteredTeams = this.filterTeamsByUserRights(uniqueTeams);

        // Ajouter chaque équipe comme option
        filteredTeams.forEach((team) => {
          options.push({
            text: team,
            value: team,
          });
        });

        return options;
      } catch (error) {
        console.error("Error building team items:", error);
        return options; // Retourner au moins l'option "Toutes les équipes"
      }
    },
    filteredTeamItems() {
      if (!this.teamSearch) {
        return this.teamItems;
      }

      const search = this.teamSearch.toLowerCase();
      return this.teamItems.filter((item) =>
        item.text.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    ...mapMutations(["setClientList", "setBaseList"]),
    openTaskDetails(task) {
      this.selectedTask = task;
      this.showTaskDetails = true;
    },
    formatedCreatedAt(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    // Gestion du drag over sur la timeline
    onTimelineDragOver(event) {
      if (!this.isEncadrement) return;
      event.preventDefault();
    },

    onTimelineDragEnter(event) {
      if (!this.isEncadrement) return;
      const timeline = event.target.closest(".info-timeline");
      if (timeline) {
        timeline.classList.add("timeline-drag-over");
      }
    },

    onTimelineDragLeave(event) {
      const timeline = event.target.closest(".info-timeline");
      if (timeline) {
        timeline.classList.remove("timeline-drag-over");
      }
    },

    // Gestion du drop sur la timeline
    async onTimelineDrop(event) {
      try {
        if (!this.isEncadrement) return;
        event.preventDefault();

        let taskId;
        try {
          const dragData = JSON.parse(event.dataTransfer.getData("text/plain"));
          taskId = dragData.taskId;
          console.log("Données du drag:", dragData);
        } catch {
          taskId = this.dragCache?.taskId;
        }

        if (!taskId) return;

        const task = this.taskSupportVision.find((t) => t._id === taskId);
        if (!task) return;

        // Trouver la cellule exacte du drop
        const timeline = event.target.closest(".info-timeline");
        const dayCell = event.target.closest(".day-cell");
        let dropDate;

        if (dayCell) {
          // Récupérer l'index de la cellule
          const cells = timeline.querySelectorAll(".day-cell");
          const index = Array.from(cells).indexOf(dayCell);
          dropDate = this.flattenedDays[index].date;
        } else {
          // Fallback sur le calcul par position si nécessaire
          const rect = timeline.getBoundingClientRect();
          const dayWidth = rect.width / this.flattenedDays.length;
          const offsetX = event.clientX - rect.left;
          const dayIndex = Math.floor(offsetX / dayWidth);
          dropDate = this.flattenedDays[dayIndex].date;
        }

        const formattedDate =
          moment(dropDate).format("YYYY-MM-DD") + " 12:00:00";

        // Appel API avec la date ajustée
        const response = await DeveloppementService.assignToProvisional(
          taskId,
          {
            previsionDate: formattedDate,
          }
        );

        if (response?.data) {
          const taskIndex = this.taskSupportVision.findIndex(
            (t) => t._id === taskId
          );
          if (taskIndex !== -1) {
            this.$set(this.taskSupportVision, taskIndex, response.data);
          }

          this.clearCaches();
          await this.refreshCalendarData();

          this.$nInfo("Tâche déplacée vers les dates prévisionnelles");
        }
      } catch (error) {
        console.error("Erreur lors du déplacement vers la timeline:", error);
        this.$nError("Erreur lors du déplacement de la tâche");
      } finally {
        const timeline = event.target.closest(".info-timeline");
        if (timeline) {
          timeline.classList.remove("timeline-drag-over");
        }
        this.dragCache = null;
        this.isDragging = false;
      }
    },
    handleZoomChange() {
      const rowHeight = this.baseRowHeight * this.zoomLevel;

      // Mettre à jour les lignes du calendrier
      document.querySelectorAll(".resource-row").forEach((row) => {
        row.style.height = `${rowHeight}px`;
        row.style.minHeight = `${rowHeight}px`;
      });

      // Mettre à jour les ressources dans la colonne de gauche
      document.querySelectorAll(".resource-name").forEach((resource) => {
        resource.style.height = `${rowHeight}px`;
        resource.style.minHeight = `${rowHeight}px`;
      });

      localStorage.setItem("calendarZoomLevel", this.zoomLevel);

      this.recalculateCalendarHeight();
      this.ressourcesNumberCalculatedHeight();
    },

    increaseZoom() {
      if (this.zoomLevel < 2.5) {
        this.zoomLevel += 0.1;
        this.handleZoomChange();
      }
    },

    decreaseZoom() {
      if (this.zoomLevel > 0.5) {
        this.zoomLevel -= 0.1;
        this.handleZoomChange();
      }
    },
    togglePinResource(resourceId) {
      if (this.pinnedResourceId === resourceId) {
        this.pinnedResourceId = null;
      } else {
        this.pinnedResourceId = resourceId;
      }
      // Sauvegarder la préférence dans le localStorage
      localStorage.setItem("pinnedResourceId", this.pinnedResourceId);

      // Forcer la mise à jour de la vue
      this.$nextTick(() => {
        this.updateCalendarView();
      });
    },
    // Classes pour les lignes de ressources
    getResourceRowClasses(resourceId) {
      return {
        disabled: !this.canModifyResource(resourceId),
      };
    },

    // Classes pour les événements
    getEventClasses(event, resourceId) {
      const resourceName = event.carte.assignement.assignedTo;
      const currentUserName = `${this.informations.firstname} ${this.informations.lastname}`;
      const isOwnResource = resourceName === currentUserName;

      // Simplifié pour tester
      const canDrag =
        isOwnResource && this.$store.getters.canDragStart(resourceName);

      return {
        draggable: canDrag,
        "not-draggable": !canDrag,
        event: true,
      };
    },
    // Styles pour la jauge de charge
    getWorkloadStyle(resource, date) {
      const workload = this.getDailyWorkload(resource, date);
      return {
        width:
          this.getWorkloadPercentage(workload.totalHours, workload.limit) + "%",
      };
    },

    // Classes pour la jauge de charge
    getWorkloadClasses(resource, date) {
      const workload = this.getDailyWorkload(resource, date);
      return [
        this.getWorkloadClass(workload.totalHours, workload.limit),
        { disabled: !this.canModifyResource(resource.id) },
      ];
    },
    // Gestion du drag & drop
    handleTaskDragStart(event, task) {
      if (!this.isEncadrement) {
        event.preventDefault();
        return;
      }

      this.isDragging = true;
      document.body.style.cursor = "grabbing";

      // Stocker les données de la tâche dans le cache avant le setData
      this.dragCache = {
        taskId: task._id,
        type: task?.carte?.assignement?.assigned
          ? "assigned-task"
          : "unassigned-task",
        duration: task?.carte?.duree?.duree || 0,
      };

      // Ajouter les données sous forme de chaîne JSON
      try {
        event.dataTransfer.setData(
          "text/plain",
          JSON.stringify(this.dragCache)
        );
        event.target.classList.add("dragging");
      } catch (error) {
        console.error("Erreur lors du setData:", error);
      }
    },

    getDayIndexesForResource(resourceId) {
      // Retourne un tableau des index de jours où la ressource a des événements
      return this.flattenedDays.reduce((indexes, day, index) => {
        const resource = this.resources.find((r) => r.id === resourceId);
        if (resource && this.getDayEvents(resource, day.date).length > 0) {
          indexes.push(index);
        }
        return indexes;
      }, []);
    },
    openCalendarForResource(resource) {
      this.selectedResourceId = resource.id;
      this.selectedResourceName = resource.name;
      this.selectedResource = resource;
      // Initialiser la sélection dans le v-select du calendrier
      this.$nextTick(() => {
        this.showResourceCalendar = true;
      });
    },

    getTeamOptions() {
      if (!this.teams?.data) return [];

      // Créer la liste des options pour le select
      const options = [{ text: "Toutes les équipes", value: "all" }];

      // Ajouter les équipes uniques
      const uniqueTeams = [
        ...new Set(
          this.teams.data
            .filter((t) => t.teamRole?.team)
            .map((t) => t.teamRole.team)
        ),
      ];

      uniqueTeams.forEach((team) => {
        options.push({ text: team, value: team });
      });

      this.teamOptions = options;
      return this.teamOptions;
    },
    filterTeamsByUserRights(teams) {
      // Si admin ou peut voir toutes les équipes
      if (
        this.userRights.isAdmin ||
        this.userRights.teamId === "Encadrement" ||
        this.userRights.scope === "all"
      ) {
        return teams;
      }

      // Si droits d'équipe
      if (this.userRights.scope === "team") {
        return teams.filter((team) => team === this.userRights.teamId);
      }

      // Si droits personnels
      if (this.userRights.scope === "self") {
        const userTeam = this.teams.find(
          (t) => t.teamRole?.userId === this.informations.id
        )?.teamRole?.team;

        return userTeam ? [userTeam] : [];
      }

      return [];
    },

    // Gestionnaire de sélection d'équipe
    handleTeamSelection(teamValue) {
      this.selectedTeam = teamValue;

      // Mettre à jour le filtre
      this.$nextTick(() => {
        this.filterByTeam();
      });

      // Émettre l'événement pour d'autres composants si nécessaire
      this.$emit("team-changed", teamValue);
    },

    filterByTeam() {
      if (this.selectedTeam === "all") {
        this.selectedProject = "all";
      } else {
        this.selectedProject = this.selectedTeam;
      }
    },

    // Réinitialiser la sélection si nécessaire
    resetTeamSelection() {
      this.selectedTeam = "all";
      this.filterByTeam();
    },
    handleMonthSelection({ year, month }) {
      // Mettre à jour la date courante avec l'année et le mois sélectionnés
      this.currentDate = moment(`${year}-${month}-01`, "YYYY-M-DD").toDate();

      // Mettre à jour la vue du calendrier
      this.updateVisibleWeeks();
      this.updateVisibleMonths();

      // Forcer la mise à jour du composant
      this.$forceUpdate();
    },
    openInNewWindow() {
      const routeData = this.$router.resolve({ name: "SettingsCartes" });
      window.open(routeData.href, "_blank");
    },
    // Ajoutez cette méthode dans la section methods du composant
    getChargeJournaliere(resource, date) {
      try {
        // S'assurer que date est un objet moment
        const targetDate = moment(date).startOf("day");

        // Filtrer les événements pour ce jour et cette ressource
        const events = this.taskSupportVision.filter((task) => {
          if (
            !task.carte ||
            !task.carte.assignement ||
            !task.carte.assignement.dateDebut ||
            !task.carte.assignement.dateFin ||
            !task.carte.duree
          ) {
            return false;
          }

          const taskStart = moment(task.carte.assignement.dateDebut).startOf(
            "day"
          );
          const taskEnd = moment(task.carte.assignement.dateFin).startOf("day");

          return (
            task.carte.assignement.assignedTo === resource.name &&
            moment(targetDate).isBetween(taskStart, taskEnd, "day", "[]")
          );
        });

        // Calculer la charge totale
        const chargeTotal = events.reduce((total, task) => {
          if (!task.carte.duree || !task.carte.duree.duree) return total;

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);
          const durationInDays = taskEnd.diff(taskStart, "days") + 1;

          // Si la tâche s'étend sur plusieurs jours, répartir les heures
          const hoursPerDay = Number(task.carte.duree.duree) / durationInDays;

          return total + hoursPerDay;
        }, 0);

        // Arrondir à une décimale
        return Math.round(chargeTotal * 10) / 10;
      } catch (error) {
        console.error("Erreur dans getChargeJournaliere:", error);
        return 0;
      }
    },
    getDailyWorkloadparheuredivisé(resource, date) {
      try {
        const cacheKey = `${resource.id}-${moment(date).format("YYYY-MM-DD")}`;
        if (this.workloadCache[cacheKey]) {
          return this.workloadCache[cacheKey];
        }

        const dayStart = moment(date).startOf("day");
        const dayEnd = moment(date).endOf("day");

        const events = this.getEventsForResource(resource).filter((task) => {
          if (
            !task.carte?.assignement?.dateDebut ||
            !task.carte?.assignement?.dateFin ||
            !task.carte?.duree
          ) {
            return false;
          }

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);

          return (
            taskStart.isSameOrBefore(dayEnd) && taskEnd.isSameOrAfter(dayStart)
          );
        });

        const totalHours = events.reduce((total, task) => {
          if (!task.carte.duree) return total;

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);

          if (
            taskStart.isSame(dayStart, "day") &&
            taskEnd.isSame(dayEnd, "day")
          ) {
            return total + Number(task.carte.duree.duree);
          }

          const durationInDays = taskEnd.diff(taskStart, "days") + 1;
          const hoursPerDay = Number(task.carte.duree.duree) / durationInDays;

          return total + hoursPerDay;
        }, 0);

        const roundedTotal = Math.round(totalHours * 10) / 10;
        let limit = this.ressourceLimite;

        const taskWithLimit = events.find(
          (task) => task.carte.assignement.ressources?.limiteQuotidienne
        );

        if (taskWithLimit?.carte.assignement.ressources) {
          limit = taskWithLimit.carte.assignement.ressources.limiteQuotidienne;
        }

        const result = {
          totalHours: roundedTotal,
          limit,
          details: events.map((task) => ({
            id: task._id,
            title: task.carte.libelle,
            hours: task.carte.duree ? task.carte.duree.duree : 0,
            type: task.carte.impactType,
          })),
        };

        this.workloadCache[cacheKey] = result;
        return result;
      } catch (error) {
        console.error("Erreur dans getDailyWorkload:", error);
        return {
          totalHours: 0,
          limit: this.ressourceLimite,
          details: [],
        };
      }
    },
    getDailyWorkloadok(resource, date) {
      try {
        const cacheKey = `${resource.id}-${moment(date).format("YYYY-MM-DD")}`;
        if (this.workloadCache[cacheKey]) {
          return this.workloadCache[cacheKey];
        }

        const dayStart = moment(date).startOf("day");
        const dayEnd = moment(date).endOf("day");

        const events = this.getEventsForResource(resource).filter((task) => {
          if (
            !task.carte?.assignement?.dateDebut ||
            !task.carte?.assignement?.dateFin ||
            !task.carte?.duree
          ) {
            return false;
          }

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);

          return (
            taskStart.isSameOrBefore(dayEnd) && taskEnd.isSameOrAfter(dayStart)
          );
        });

        let totalHours = 0;
        let remainingHoursToDistribute = 0;
        const taskDetails = [];

        // Premier passage : traiter les tâches normalement
        events.forEach((task) => {
          if (!task.carte.duree) return;

          const taskDuration = Number(task.carte.duree.duree);

          // Si la durée dépasse la limite journalière
          if (taskDuration > this.ressourceLimite) {
            // Ajouter la limite pour ce jour
            totalHours += this.ressourceLimite;
            // Stocker le surplus à distribuer sur les jours suivants
            remainingHoursToDistribute += taskDuration - this.ressourceLimite;

            taskDetails.push({
              id: task._id,
              title: task.carte.libelle,
              hours: this.ressourceLimite,
              type: task.carte.impactType,
            });
          } else {
            // Si la durée ne dépasse pas la limite, l'afficher complètement
            totalHours += taskDuration;

            taskDetails.push({
              id: task._id,
              title: task.carte.libelle,
              hours: taskDuration,
              type: task.carte.impactType,
            });
          }
        });

        // Si nous avons des heures à distribuer et ce n'est pas le premier jour
        if (remainingHoursToDistribute > 0) {
          const isFirstDay = events.some((task) =>
            moment(task.carte.assignement.dateDebut).isSame(dayStart, "day")
          );

          if (!isFirstDay) {
            // Ajouter les heures restantes dans la limite de la capacité journalière
            const availableCapacity = Math.max(
              0,
              this.ressourceLimite - totalHours
            );
            const hoursToAdd = Math.min(
              remainingHoursToDistribute,
              availableCapacity
            );

            if (hoursToAdd > 0) {
              totalHours += hoursToAdd;
              taskDetails.push({
                id: "overflow",
                title: "Report",
                hours: hoursToAdd,
                type: "overflow",
              });
            }
          }
        }

        let limit = this.ressourceLimite;
        const taskWithLimit = events.find(
          (task) => task.carte.assignement.ressources?.limiteQuotidienne
        );

        if (taskWithLimit?.carte.assignement.ressources) {
          limit = taskWithLimit.carte.assignement.ressources.limiteQuotidienne;
        }

        const result = {
          totalHours: Math.round(totalHours * 10) / 10,
          limit,
          details: taskDetails,
        };

        this.workloadCache[cacheKey] = result;
        return result;
      } catch (error) {
        console.error("Erreur dans getDailyWorkload:", error);
        return {
          totalHours: 0,
          limit: this.ressourceLimite,
          details: [],
        };
      }
    },
    getDailyWorkload(resource, date) {
      try {
        const cacheKey = `${resource.id}-${moment(date).format("YYYY-MM-DD")}`;
        if (this.workloadCache[cacheKey]) {
          return this.workloadCache[cacheKey];
        }

        const dayStart = moment(date).startOf("day");
        const dayEnd = moment(date).endOf("day");

        const events = this.getEventsForResource(resource).filter((task) => {
          if (
            !task.carte?.assignement?.dateDebut ||
            !task.carte?.assignement?.dateFin ||
            !task.carte?.duree
          ) {
            return false;
          }

          const taskStart = moment(task.carte.assignement.dateDebut);
          const taskEnd = moment(task.carte.assignement.dateFin);

          return (
            taskStart.isSameOrBefore(dayEnd) && taskEnd.isSameOrAfter(dayStart)
          );
        });

        // Séparer les tâches normales et celles avec forceDepassement
        const regularTasks = events.filter(
          (task) => !task.carte.assignement.forceDepassement
        );
        const forcedTasks = events.filter(
          (task) => task.carte.assignement.forceDepassement
        );

        // Calculer le total des tâches normales (limité à 7h au total)
        let totalRegularHours = Math.min(
          regularTasks.reduce(
            (total, task) => total + Number(task.carte.duree.duree),
            0
          ),
          7
        );

        // Ajouter les tâches avec forceDepassement individuellement
        const taskDetails = [];
        let totalHours = totalRegularHours;

        regularTasks.forEach((task) => {
          taskDetails.push({
            id: task._id,
            title: task.carte.libelle,
            hours: task.carte.duree.duree,
            type: task.carte.impactType,
          });
        });

        forcedTasks.forEach((task) => {
          // Pour chaque tâche avec forceDepassement, on ajoute ses heures complètes
          totalHours += Number(task.carte.duree.duree);
          taskDetails.push({
            id: task._id,
            title: task.carte.libelle,
            hours: task.carte.duree.duree,
            type: task.carte.impactType,
            isForceDepassement: true,
          });
        });

        const result = {
          totalHours: Math.round(totalHours * 10) / 10,
          limit: 7,
          details: taskDetails,
        };

        this.workloadCache[cacheKey] = result;
        return result;
      } catch (error) {
        console.error("Erreur dans getDailyWorkload:", error);
        return {
          totalHours: 0,
          limit: 7,
          details: [],
        };
      }
    },

    // Détermine si une date est un jour ouvrable
    isWorkingDay(date) {
      const dayOfWeek = moment(date).day();
      return dayOfWeek !== 0 && dayOfWeek !== 6; // 0 = dimanche, 6 = samedi
    },

    // Trouve le prochain jour ouvrable
    getNextWorkingDay(date) {
      let nextDay = moment(date).add(1, "days");
      while (!this.isWorkingDay(nextDay)) {
        nextDay = nextDay.add(1, "days");
      }
      return nextDay;
    },

    // Trouve le jour ouvrable précédent
    getPreviousWorkingDay(date) {
      let prevDay = moment(date).subtract(1, "days");
      while (!this.isWorkingDay(prevDay)) {
        prevDay = prevDay.subtract(1, "days");
      }
      return prevDay;
    },
    getWorkloadPercentage(hours, limit) {
      return Math.min((hours / limit) * 100, 100);
    },
    // Ajuste une plage de dates pour exclure les weekends
    adjustDateRangeForWeekends(startDate, endDate) {
      let adjustedStart = moment(startDate);
      let adjustedEnd = moment(endDate);

      // Si la date de début tombe un weekend, la déplacer au prochain jour ouvrable
      if (!this.isWorkingDay(adjustedStart)) {
        adjustedStart = this.getNextWorkingDay(adjustedStart);
      }

      // Si la date de fin tombe un weekend, la déplacer au jour ouvrable précédent
      if (!this.isWorkingDay(adjustedEnd)) {
        adjustedEnd = this.getPreviousWorkingDay(adjustedEnd);
      }

      return {
        start: adjustedStart,
        end: adjustedEnd,
      };
    },

    // Compte le nombre de jours ouvrables entre deux dates
    getWorkingDaysBetween(startDate, endDate) {
      let workingDays = 0;
      let currentDate = moment(startDate);
      const end = moment(endDate);

      while (currentDate.isSameOrBefore(end)) {
        if (this.isWorkingDay(currentDate)) {
          workingDays++;
        }
        currentDate.add(1, "days");
      }

      return workingDays;
    },

    getWorkloadClass(hours, limit) {
      const percentage = (hours / limit) * 100;
      if (percentage <= 50) return "workload-low";
      if (percentage <= 75) return "workload-medium";
      if (percentage <= 90) return "workload-high";
      return "workload-critical";
    },

    updateTracking(resource, date, hours) {
      if (!this.trackingDebounceTimer) {
        this.trackingDebounceTimer = setTimeout(async () => {
          try {
            const events = this.getEventsForResource(resource);
            const existingEvent = events.find((event) =>
              moment(event.carte.assignement.dateDebut).isSame(date, "day")
            );

            if (existingEvent?.carte.assignement.ressources) {
              let tracking =
                existingEvent.carte.assignement.ressources.tracking || [];
              const dateStr = moment(date).format("YYYY-MM-DD");
              const existingIndex = tracking.findIndex(
                (t) => t.date === dateStr
              );

              if (existingIndex === -1) {
                tracking.push({
                  date: dateStr,
                  heures: hours,
                  timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                });
              } else {
                tracking[existingIndex] = {
                  ...tracking[existingIndex],
                  heures: hours,
                  timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
                };
              }

              await DeveloppementService.updateTaskTracking(existingEvent._id, {
                tracking,
              });

              existingEvent.carte.assignement.ressources.tracking = tracking;
            }
          } catch (error) {
            console.error("Erreur lors de la mise à jour du tracking:", error);
            this.$nError("Erreur lors de la mise à jour du tracking");
          } finally {
            this.trackingDebounceTimer = null;
          }
        }, 5000); // Délai de 5 secondes
      }
    },
    handleClick() {
      this.menu = true;
    },
    searchResourceFunc() {
      // Réinitialiser la vue du calendrier si nécessaire
      this.updateVisibleWeeks();
      this.updateVisibleMonths();

      // Filtrer les ressources
      const searchTerm = this.searchResource.toLowerCase();
      this.filteredResources = this.resources.filter((resource) => {
        resource.name.toLowerCase().includes(searchTerm);
      });

      // Mettre à jour l'affichage du calendrier
      this.$nextTick(() => {
        // Recalculer la hauteur des lignes du calendrier si nécessaire
        this.recalculateCalendarHeight();

        // Faire défiler jusqu'à la première ressource correspondante
        if (this.filteredResources.length > 0) {
          const firstMatchingResource = this.filteredResources[0];
          const resourceElement = this.$el.querySelector(
            `[data-resource-id="${firstMatchingResource.id}"]`
          );
          if (resourceElement) {
            resourceElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      });
    },
    recalculateCalendarHeight() {
      const calendrierRessource = this.$refs.calendrierRessources;
      const resourceNames = this.$refs.resourceNames;

      // Calculer la hauteur de base requise
      const headerHeight = 90; // Hauteur du menu
      const infoRowHeight = 250; // Hauteur de la section info
      const resourceHeight = 80; // Hauteur par ressource
      const resourceCount = this.filteredResources.length;

      // Calcul de la hauteur totale
      const totalHeight =
        headerHeight + infoRowHeight + resourceCount * resourceHeight;

      // Définir la hauteur minimale et maximale
      const minHeight = 300; // Hauteur minimum
      const maxHeight = window.innerHeight - 170; // Hauteur maximum

      // Appliquer la hauteur calculée en respectant min/max
      const newHeight = Math.min(Math.max(totalHeight, minHeight), maxHeight);

      // Appliquer la nouvelle hauteur aux deux éléments
      if (calendrierRessource) {
        calendrierRessource.style.height = `${newHeight}px`;
      }
      if (resourceNames) {
        resourceNames.style.height = `${newHeight}px`;
      }
      this.ressourcesNumberCalculatedHeight();
    },
    formatMonth(date) {
      return moment(date).format("MMM YYYY");
    },
    formatDay(date, format) {
      return moment(date).format(format);
    },
    formatEventTime(event) {
      return moment(event.carte.assignement.dateDebut).format("HH:mm");
    },
    changeDate(delta) {
      this.currentDate = moment(this.currentDate)
        .add(delta, this.currentView)
        .toDate();
      this.updateVisibleWeeks();
      this.updateVisibleMonths();
    },
    goToToday() {
      // Obtenir la date d'aujourd'hui
      const today = moment();

      // Définir la date courante sur aujourd'hui
      this.currentDate = today.toDate();

      // Vider les caches
      this.clearCaches();

      // Mettre à jour la vue
      this.updateVisibleWeeks();
      this.updateVisibleMonths();

      // Faire défiler jusqu'à aujourd'hui si nécessaire
      this.$nextTick(() => {
        const todayCell = this.$el.querySelector(".day-cell.today");
        const resourcesGrid = this.$refs.resourcesGrid;

        if (todayCell && resourcesGrid) {
          todayCell.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      });
    },
    updateVisibleWeeks() {
      try {
        let start;
        let totalDays;
        const today = moment();
        let startOfMonth;

        switch (this.currentView) {
          case "day":
            start = moment(this.currentDate);
            totalDays = 1;
            break;
          case "week":
            start = moment(this.currentDate).startOf("week");
            totalDays = 7;
            break;
          case "month":
          default:
            startOfMonth = moment(this.currentDate).startOf("month");
            start = moment(this.currentDate);
            totalDays = 42;
            break;
        }

        const days = new Array(totalDays).fill(null).map((_, index) => ({
          date: moment(start)
            .add(index, "days")
            .toDate(),
        }));

        // Création des semaines
        const weeks = [];
        for (let i = 0; i < days.length; i += 7) {
          if (this.currentView === "day") {
            weeks.push([days[i]]);
          } else {
            weeks.push(days.slice(i, i + 7));
          }
        }

        this.visibleWeeks = weeks;
        this.updateVisibleMonths();
      } catch (error) {
        console.error("Erreur dans updateVisibleWeeks:", error);
      }
    },

    async editTask(task) {
      const resourceId = task.carte.assignement.assignedTo;

      // Vérifier les droits d'édition
      if (
        !this.$store.getters.canRightClick(
          task._id,
          this.getUserIdFromName(resourceId)
        )
      ) {
        this.$nError("Vous n'avez pas les droits pour éditer cette tâche");
        return;
      }

      this.taskEnCours = task;
      this.showTaskModal = true;
    },
    async onTaskUpdated(updatedTask) {
      try {
        if (!updatedTask) return;

        const resource = this.resources.find(
          (r) => r.name === updatedTask.carte.assignement.assignedTo
        );

        if (!resource) return;

        const startDate = moment(updatedTask.carte.assignement.dateDebut);
        const nouvellesHeures = updatedTask.carte.duree?.duree || 0;
        const chargeJournaliere = this.calculateChargeJournaliere(
          resource,
          startDate.toDate()
        );

        // Ne pas compter les heures de la tâche elle-même dans le calcul de la charge
        const taskCurrentHours =
          this.taskSupportVision.find((t) => t._id === updatedTask._id)?.carte
            .duree?.duree || 0;
        const actualCharge = chargeJournaliere - taskCurrentHours;

        if (
          actualCharge + nouvellesHeures > this.ressourceLimite &&
          !updatedTask.carte.assignement.forceDepassement
        ) {
          const distribution = await this.calculateOptimalDistribution(
            updatedTask._id,
            resource,
            startDate.toDate(),
            nouvellesHeures
          );

          if (distribution.success) {
            updatedTask.carte.assignement = {
              ...updatedTask.carte.assignement,
              dateDebut: distribution.assignement.dateDebut,
              dateFin: distribution.assignement.dateFin,
            };

            const taskIndex = this.taskSupportVision.findIndex(
              (t) => t._id === updatedTask._id
            );
            if (taskIndex !== -1) {
              this.$set(this.taskSupportVision, taskIndex, updatedTask);
            }

            this.clearCaches();
            await this.refreshCalendarData();
            this.$nInfo("Tâche mise à jour et répartie sur plusieurs jours");
            return;
          }
        }

        // Mise à jour normale si pas de dépassement
        const taskIndex = this.taskSupportVision.findIndex(
          (t) => t._id === updatedTask._id
        );
        if (taskIndex !== -1) {
          this.$set(this.taskSupportVision, taskIndex, updatedTask);
        }

        this.clearCaches();
        await this.refreshCalendarData();
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la tâche:", error);
        this.$nError("Erreur lors de la mise à jour de la tâche");
      }
    },

    // Fonction utilitaire pour diviser un tableau en morceaux
    chunk(array, size) {
      const chunked = [];
      for (let i = 0; i < array.length; i += size) {
        chunked.push(array.slice(i, i + size));
      }
      return chunked;
    },
    updateVisibleMonths() {
      if (!this.visibleWeeks.length || !this.visibleWeeks[0].length) return;

      const start = moment(this.visibleWeeks[0][0].date).startOf("month");
      const end = moment(
        this.visibleWeeks[this.visibleWeeks.length - 1][0].date
      ).endOf("month");

      this.visibleMonths = [];
      let month = start.clone();

      while (month.isSameOrBefore(end, "month")) {
        this.visibleMonths.push(month.toDate());
        month.add(1, "month");
      }

      this.$nextTick(() => {
        const monthHeaders = this.$el.querySelectorAll(".month-header");
        let currentPosition = 0;

        monthHeaders.forEach((header, index) => {
          const currentMonth = moment(this.visibleMonths[index]);

          // Compter les jours jusqu'au dernier jour du mois courant
          const daysCount = this.flattenedDays.filter((day) =>
            moment(day.date).isSame(currentMonth, "month")
          ).length;

          // Largeur basée sur le nombre exact de jours dans le mois sachant que chaque cellule est de 3.3%
          const width = daysCount * 3;

          header.style.height = "100%";
          header.style.width = `${width}%`;
          header.style.minWidth = `${width}%`;
        });
      });
    },
    getWeekNumber(date) {
      return moment(date).week();
    },
    isWeekend(date) {
      return moment(date).day() === 0 || moment(date).day() === 6;
    },
    isToday(date) {
      return moment(date).isSame(moment(), "day");
    },
    isPast(date) {
      return moment(date).isBefore(moment(), "day");
    },
    getAvatarColor(ressource) {
      let hash = 0;
      for (let i = 0; i < ressource.name.length; i++) {
        hash = ressource.name.charCodeAt(i) + ((hash << 5) - hash);
      }
      const color = `hsl(${hash % 360}, 70%, 50%)`;
      // return color;
      return "#673AB7";
    },
    getEventsForResource(resource) {
      if (!this.visibleWeeks.length || !this.visibleWeeks[0].length) {
        return [];
      }

      const cacheKey = `${resource.id}-${moment(this.currentDate).format(
        "YYYY-MM-DD"
      )}`;
      if (this.eventsCache[cacheKey]) {
        return this.eventsCache[cacheKey];
      }

      const startDate = moment(this.visibleWeeks[0][0].date).startOf("day");
      const endDate = moment(
        this.visibleWeeks[this.visibleWeeks.length - 1][
          this.visibleWeeks[this.visibleWeeks.length - 1].length - 1
        ].date
      ).endOf("day");

      const filteredEvents = this.taskSupportVision
        .filter(
          (task) =>
            task.carte?.assignement?.assignedTo === resource.name &&
            task.carte?.assignement?.dateDebut &&
            task.carte?.assignement?.dateFin &&
            moment(task.carte.assignement.dateFin).isSameOrAfter(startDate) &&
            moment(task.carte.assignement.dateDebut).isSameOrBefore(endDate)
        )
        .map((task) => ({
          ...task,
          dragendListener: (e) => this.onDragEnd(e),
        }));

      this.eventsCache[cacheKey] = filteredEvents;
      return filteredEvents;
    },
    getEventStyle(event) {
      const startDate = moment(event.carte.assignement.dateDebut);
      const endDate = moment(event.carte.assignement.dateFin);
      const startIndex = this.getDayIndex(startDate);
      const endIndex = this.getDayIndex(endDate);
      const totalDays = this.flattenedDays.length;

      const left = (startIndex / totalDays) * 100 + "%";
      const width = ((endIndex - startIndex + 1) / totalDays) * 100 + "%";

      return {
        left: left,
        width: width,
        backgroundColor: this.getColorByTeam(event.carte.impactType),
      };
    },
    getEventKey(event) {
      // Générer une clé unique basée sur plusieurs propriétés de l'événement
      return `event-${event._id || ""}-${event.carte.libelle}-${
        event.carte.assignement.dateDebut
      }-${event.carte.assignement.dateFin}`;
    },
    getEstimationsForDay(date) {
      const dayTasks = this.getTasksForDay(date);

      if (dayTasks.length === 0) return "";

      const totalDuration = dayTasks.reduce((total, task) => {
        return total + (task.carte.duree?.duree || 0);
      }, 0);

      return `${totalDuration}h\n${dayTasks.length} tâche${
        dayTasks.length > 1 ? "s" : ""
      }`;
    },
    getTasksForDay(date) {
      if (!date) return [];

      const filteredTasks = this.taskSupportVision.filter((task) => {
        // Vérification de l'existence de la tâche et de ses propriétés
        if (!task?.carte) return false;

        // Initialiser assigned à false si assignement n'existe pas
        const assigned = task.carte.assignement?.assigned || false;

        // Vérifier l'existence de datePrevisionnel et previsionDate
        const hasPrevisionDate = task.carte?.datePrevisionnel?.previsionDate;

        // Ne comparer les dates que si on a une date prévisionnelle
        const dateMatch =
          hasPrevisionDate &&
          moment(task.carte.datePrevisionnel.previsionDate)
            .startOf("day")
            .isSame(moment(date).startOf("day"));

        // La tâche doit être non assignée et avoir une date qui correspond
        return !assigned && hasPrevisionDate && dateMatch;
      });

      return filteredTasks;
    },

    onPrevisionDragStart(event, task) {
      this.isDragging = true;
      document.body.style.cursor = "grabbing";

      // Stocker les informations de la tâche prévisionnelle
      const dragData = {
        type: "prevision-task",
        taskId: task._id,
        duration: task.carte.duree.duree,
      };

      event.dataTransfer.setData("text/plain", JSON.stringify(dragData));

      // Ajouter une classe visuelle pendant le drag
      event.target.classList.add("dragging");
    },

    onPrevisionDragEnd(event) {
      this.isDragging = false;
      document.body.style.cursor = "default";
      event.target.classList.remove("dragging");
    },

    // async onDrop(event, resource, date) {
    //   try {
    //     event.preventDefault();

    //     // Vérifier si on peut déposer sur cette ressource
    //     if (!this.$store.getters.canDrop(resource.id)) {
    //       return;
    //     }

    //     if (!this.isWorkingDay(date)) {
    //       const nextWorkingDay = this.getNextWorkingDay(date).toDate();
    //       date = nextWorkingDay;
    //       this.$nInfo("La tâche a été déplacée au prochain jour ouvrable");
    //     }

    //     let taskId, taskType;
    //     try {
    //       const dragDataStr = event.dataTransfer.getData("text/plain");
    //       const dragData = JSON.parse(dragDataStr);

    //       if (typeof dragData === "object") {
    //         taskId = dragData.taskId;
    //         taskType = dragData.type;
    //       } else {
    //         taskId = dragDataStr;
    //       }
    //     } catch (e) {
    //       if (!this.dragCache) {
    //         console.error("No drag data available");
    //         return;
    //       }
    //       taskId = this.dragCache.taskId;
    //       taskType = this.dragCache.type;
    //     }

    //     if (!taskId) {
    //       console.error("No task ID found");
    //       return;
    //     }

    //     // Trouver la tâche
    //     const task = this.taskSupportVision.find((t) => t._id === taskId);
    //     if (!task?.carte) {
    //       console.error("Task or task.carte not found");
    //       return;
    //     }

    //     // Calcul des nouvelles dates
    //     const newStartDate = moment(date).startOf("day");

    //     let newEndDate;
    //     if (
    //       task.carte?.assignement?.dateDebut &&
    //       task.carte?.assignement?.dateFin
    //     ) {
    //       const duration = moment(task.carte.assignement.dateFin).diff(
    //         moment(task.carte.assignement.dateDebut),
    //         "days"
    //       );
    //       newEndDate = moment(date)
    //         .add(duration, "days")
    //         .endOf("day");
    //     } else {
    //       const taskDuration = task.carte?.duree?.duree || 0;
    //       const workingDaysNeeded = Math.ceil(taskDuration / 7);
    //       let currentEndDate = moment(date).clone();

    //       while (
    //         this.getWorkingDaysBetween(newStartDate, currentEndDate) <
    //         workingDaysNeeded
    //       ) {
    //         currentEndDate.add(1, "days");
    //       }
    //       newEndDate = currentEndDate.endOf("day");
    //     }

    //     // Vérification de la charge de travail
    //     const chargeJournaliere = this.calculateChargeJournaliere(
    //       resource,
    //       date
    //     );
    //     const nouvellesHeures = task.carte?.duree?.duree || 0;

    //     if (
    //       chargeJournaliere + nouvellesHeures > this.ressourceLimite &&
    //       !task.carte.assignement?.forceDepassement
    //     ) {
    //       const suggestionsDates = this.findDatesDisponibles(
    //         resource,
    //         nouvellesHeures
    //       ).filter((date) => this.isWorkingDay(date));

    //       this.taskEnCours = task;
    //       this.dateDestination = date;
    //       this.ressourceDestination = resource;
    //       this.heuresPlanifiees = chargeJournaliere;
    //       this.nouvellesHeures = nouvellesHeures;
    //       this.$set(this, "datesSuggestions", suggestionsDates);
    //       this.showLimitAlert = true;
    //       return;
    //     }

    //     // Mise à jour de la tâche
    //     await this.updateTaskAssignment(
    //       task,
    //       resource,
    //       newStartDate,
    //       newEndDate,
    //       taskType === "unassigned-task" || taskType === "prevision-task"
    //     );

    //     // Rafraîchir les données
    //     this.clearCaches();
    //     await this.refreshCalendarData();

    //     this.$nInfo("Tâche assignée avec succès");
    //   } catch (error) {
    //     console.error("Erreur lors du drop:", error);
    //     this.$nError("Erreur lors du déplacement de la tâche");
    //   } finally {
    //     this.cleanupDragDrop();
    //   }
    // },

    async onDrop(event, resource, date) {
      try {
        event.preventDefault();
        if (!this.canDrop(resource.id)) return;

        if (!this.isWorkingDay(date)) {
          const nextWorkingDay = this.getNextWorkingDay(date).toDate();
          date = nextWorkingDay;
          this.$nInfo("La tâche a été déplacée au prochain jour ouvrable");
        }

        let dragData;
        try {
          const dragDataString = event.dataTransfer.getData("text/plain");
          dragData = dragDataString
            ? JSON.parse(dragDataString)
            : this.dragCache;
        } catch (error) {
          dragData = this.dragCache;
        }

        if (!dragData || !dragData.taskId) {
          console.error("Données de drag non disponibles");
          return;
        }

        const task = this.taskSupportVision.find(
          (t) => t._id === dragData.taskId
        );
        if (!task?.carte) return;

        // Calculer la durée en jours de la tâche originale
        const originalDuration = moment(task.carte?.assignement?.dateFin).diff(
          moment(task.carte?.assignement?.dateDebut),
          "days"
        );

        // Nouvelle date de début (au début du jour)
        const newStartDate = moment(date).startOf("day");

        // Nouvelle date de fin en conservant la même durée
        const newEndDate = moment(newStartDate)
          .add(originalDuration, "days")
          .endOf("day");

        const nouvellesHeures = task.carte?.duree?.duree || 0;
        const chargeJournaliere = this.calculateChargeJournaliere(
          resource,
          date
        );

        if (
          chargeJournaliere + nouvellesHeures > this.ressourceLimite &&
          !task.carte.assignement?.forceDepassement
        ) {
          this.taskEnCours = task;
          this.dateDestination = date;
          this.ressourceDestination = resource;
          this.heuresPlanifiees = chargeJournaliere;
          this.nouvellesHeures = nouvellesHeures;
          const suggestionsDates = this.findDatesDisponibles(
            resource,
            nouvellesHeures
          ).filter((date) => this.isWorkingDay(date));
          this.$set(this, "datesSuggestions", suggestionsDates);
          this.showLimitAlert = true;
          return;
        }

        await this.updateTaskAssignment(
          task,
          resource,
          newStartDate,
          newEndDate,
          false
        );

        this.clearCaches();
        await this.refreshCalendarData();
        this.$nInfo("Tâche assignée avec succès");
      } catch (error) {
        console.error("Erreur lors du drop:", error);
        this.$nError("Erreur lors du déplacement de la tâche");
      } finally {
        this.cleanupDragDrop();
      }
    },

    async updateTaskAssignment(
      task,
      resource,
      startDate,
      endDate,
      isPrevisionTask = false
    ) {
      try {
        const updateData = {
          assignement: {
            assigned: true,
            assignedTo: resource.name,
            dateDebut: startDate.format("YYYY-MM-DD HH:mm:ss"),
            dateFin: endDate.format("YYYY-MM-DD HH:mm:ss"),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
            forceDepassement: task.carte.assignement?.forceDepassement || false,
            ...(isPrevisionTask && {
              fromPrevision: true,
              previsionDate: task.carte.datePrevisionnel?.previsionDate,
            }),
          },
        };

        // Mise à jour serveur avant mise à jour locale
        const response = await DeveloppementService.assignTask(
          task._id,
          updateData
        );

        // Si succès serveur, mise à jour locale
        const updatedTask = {
          ...task,
          carte: {
            ...task.carte,
            assignement: {
              ...task.carte.assignement,
              ...updateData.assignement,
            },
          },
        };

        const taskIndex = this.taskSupportVision.findIndex(
          (t) => t._id === task._id
        );
        if (taskIndex !== -1) {
          this.$set(this.taskSupportVision, taskIndex, updatedTask);
        }

        this.clearCaches();
        await this.refreshCalendarData();
      } catch (error) {
        console.error("Error in updateTaskAssignment:", error);
        throw error;
      }
    },
    cleanupDragDrop() {
      this.isDragging = false;
      document.body.style.cursor = "default";
      document.querySelectorAll(".dragging").forEach((el) => {
        el.classList.remove("dragging");
      });
      // Ne pas vider le cache immédiatement au cas où on en aurait besoin
      setTimeout(() => {
        this.dragCache = null;
      }, 100);
    },

    checkRightsBeforeAction(resourceId, task = null) {
      if (!this.canModifyResource(resourceId)) {
        this.$nError(
          "Vous n'avez pas les droits pour modifier cette ressource"
        );
        return false;
      }

      if (task && !this.canModifyTask(task, resourceId)) {
        this.$nError("Vous n'avez pas les droits pour modifier cette tâche");
        return false;
      }

      return true;
    },

    getUserIdFromName(fullName) {
      const user = this.users.find(
        (user) => `${user.firstname} ${user.lastname}` === fullName
      );
      return user ? user.id : null;
    },

    onDragStart(event, taskEvent) {
      const resourceName = taskEvent.carte.assignement.assignedTo;

      const canDrag = this.$store.getters.canDragStart(resourceName);

      if (!canDrag) {
        console.log("Drag prevented:", { reason: "Permissions check failed" });
        event.preventDefault();
        return;
      }

      if (event?.dataTransfer) {
        this.isDragging = true;
        document.body.style.cursor = "grabbing";
        this.dragCache = {
          taskId: taskEvent._id,
          originalResource: resourceName,
          originalStartDate: taskEvent.carte.assignement.dateDebut,
          originalEndDate: taskEvent.carte.assignement.dateFin,
          duration:
            moment(taskEvent.carte.assignement.dateFin).diff(
              moment(taskEvent.carte.assignement.dateDebut),
              "days"
            ) + 1,
        };
        event.dataTransfer.setData("text/plain", taskEvent._id);
      }
    },

    canDragEvent(event) {
      const resourceName = event.carte.assignement.assignedTo;
      const userId = this.informations.id;
      const userTeamRole = this.teamsMap[userId];

      const canDrag = this.$store.getters.canDragStart(resourceName);

      return canDrag;
    },

    onDragEnter(event, date) {
      // Vérifier si l'élément est une cellule valide pour le drop
      const cell = event.target.closest(".day-cell");
      if (this.isDragging && cell) {
        cell.classList.add("drag-over");
      }
      this.dragEndDate = date;
    },

    onDragLeave(event) {
      const cell = event.target.closest(".day-cell");
      if (cell) {
        cell.classList.remove("drag-over");
      }
    },

    onDragEnd(event) {
      this.isDragging = false;
      document.body.style.cursor = "default";
      // Nettoyer toutes les classes drag-over
      document.querySelectorAll(".drag-over").forEach((cell) => {
        cell.classList.remove("drag-over");
      });
      this.dragCache = null;
    },

    onDragOver(event) {
      event.preventDefault();
    },

    getEventStyleWithStack(event, resource, date) {
      const startDate = moment(event.carte.assignement.dateDebut);
      const endDate = moment(event.carte.assignement.dateFin);
      const currentDate = moment(date);
      const isFirstDay = startDate.isSame(currentDate, "day");
      const isLastDay = endDate.isSame(currentDate, "day");

      // Calcul de la position verticale
      const eventsOnSameDay = this.getDayEvents(resource, date);
      const eventIndex = eventsOnSameDay.findIndex((e) => e._id === event._id);
      const topOffset = eventIndex * 24; // 24px par événement

      return {
        backgroundColor: this.getColorByTeam(event.carte.impactType),
        position: "absolute",
        height: "22px",
        top: `${topOffset}px`,
        left: "1px",
        right: "1px",
        borderRadius: "2px",
        zIndex: 1,
        borderTopLeftRadius: isFirstDay ? "2px" : "0",
        borderBottomLeftRadius: isFirstDay ? "2px" : "0",
        borderTopRightRadius: isLastDay ? "2px" : "0",
        borderBottomRightRadius: isLastDay ? "2px" : "0",
      };
    },
    calculateRowHeight(resource) {
      const maxOverlap = this.getEventsForResource(resource).reduce(
        (max, event) => {
          const overlappingEvents = this.getEventsForResource(resource).filter(
            (e) => {
              const eventStart = moment(event.carte.assignement.dateDebut);
              const eventEnd = moment(event.carte.assignement.dateFin);
              const eStart = moment(e.carte.assignement.dateDebut);
              const eEnd = moment(e.carte.assignement.dateFin);
              return (
                e._id !== event._id &&
                !(eEnd.isBefore(eventStart) || eStart.isAfter(eventEnd))
              );
            }
          );
          return Math.max(max, overlappingEvents.length + 1);
        },
        1
      );

      this.ressourcesNumberCalculatedHeight();

      // Increased base height from 60 to 80, and event height from 24 to 30
      // return Math.max(80, maxOverlap * 30 + 20); // 30px per event + 20px for the gauge
      return 80;
    },
    ressourcesNumberCalculatedHeight() {
      if (!this.resources || !this.userRights) return [];

      let filtered = this.resources;
      const userId = this.informations.id;
      const userTeamRole = this.teamsMap[userId];

      if (!userTeamRole) return [];

      const heightCalculElemAside = document.querySelector(".height-calcul");
      const calendrierAndRessourceElem = document.querySelector(
        ".calendrier-ressources"
      );
      if (this.isEncadrement) {
        const infoTimeLineHeight = "200px"; // Hauteur de la timeline
        const headerHeight = 140; // Hauteur du menu
        heightCalculElemAside.style.height = `${this.filteredResources.length *
          80 +
          headerHeight +
          infoTimeLineHeight}px`;
        calendrierAndRessourceElem.style.height = `${this.filteredResources
          .length *
          80 +
          headerHeight +
          infoTimeLineHeight}px`;
      } else if (userTeamRole.roles.planning.scope === "all") {
        // on ne fait rien
      } else {
        heightCalculElemAside.style.overflowY = "auto";
        calendrierAndRessourceElem.style.overflowY = "hidden";
      }
    },
    getDayEvents(resource, date) {
      return this.getEventsForResource(resource).filter((event) => {
        const eventStart = moment(event.carte.assignement.dateDebut).startOf(
          "day"
        );
        const eventEnd = moment(event.carte.assignement.dateFin).endOf("day");
        const cellDate = moment(date).startOf("day");

        /*
        {"__v":0,"updatedAt":"2024-12-11T10:00:47.327Z","createdAt":"2024-12-11T10:00:47.327Z","carte":{"duree":{"skipWeekends":true,"duree":1,"unite":"heures"},"historique_assignement":[{"workPlan":[{"hours":1,"date":"2024-12-20 00:00:00"}],"validDates":["2024-12-20 00:00:00","2024-12-23 00:00:00"],"assigned":true,"assignedTo":"Mohamed Khenissi","dateDebut":"2024-12-20 00:00:00","dateFin":"2024-12-23 00:00:00","dateAssignement":"2024-12-11 11:00:47","ressources":{"skipWeekends":true,"limiteQuotidienne":7,"tracking":[]}}],"assignement":{"workPlan":[{"hours":1,"date":"2024-12-20 00:00:00"}],"validDates":["2024-12-20 00:00:00","2024-12-23 00:00:00"],"assigned":true,"assignedTo":"Mohamed Khenissi","dateDebut":"2024-12-20 00:00:00","dateFin":"2024-12-23 00:00:00","dateAssignement":"2024-12-11 11:00:47","ressources":{"skipWeekends":true,"limiteQuotidienne":7,"tracking":[]}},"images":null,"sous_module":"","module":"","urgentRequestDate":"null","linkedCard":"","base":"","clientName":"","originType":"","impactType":"null","devType":"Développement","userCreate":"Mohamed Khenissi","description":"<p>rrrrrr</p>","libelle":"rrrrr"},"_id":"675962cf8d8b05a36c83143b"}
        */
        //  retirer les weekends et decaler les dates de debut et fin avec les date valides de mon retour

        return cellDate.isBetween(eventStart, eventEnd, "day", "[]");
      });
    },

    calculateChargeJournaliere(resource, date) {
      const cacheKey = `${resource.id}-${moment(date).format("YYYY-MM-DD")}`;
      if (this.workloadCache[cacheKey]) {
        return this.workloadCache[cacheKey].totalHours;
      }

      const events = this.getEventsForResource(resource).filter((event) =>
        moment(event.carte.assignement.dateDebut).isSame(date, "day")
      );

      const total = events.reduce(
        (sum, event) => sum + (event.carte.duree?.duree || 0),
        0
      );

      this.workloadCache[cacheKey] = { totalHours: total };
      return total;
    },

    findDatesDisponibles(resource, heuresNecessaires) {
      const suggestions = [];

      // Utiliser la date actuelle si dateDestination est null
      let startDate = this.dateDestination
        ? moment(this.dateDestination)
        : moment();

      // Vérifier si startDate est valide
      if (!startDate.isValid()) {
        startDate = moment(); // Fallback sur la date actuelle
      }

      for (let i = 1; i <= 30 && suggestions.length < 5; i++) {
        try {
          const testDate = startDate.clone().add(i, "days");

          // Vérifier si le testDate est valide
          if (testDate.isValid() && !this.isWeekend(testDate.toDate())) {
            const workload = this.getDailyWorkload(resource, testDate.toDate());

            if (
              workload.totalHours + heuresNecessaires <=
              this.ressourceLimite
            ) {
              suggestions.push({
                date: testDate.toDate(),
                charge: workload.totalHours,
                formattedDate: testDate.format("DD/MM/YYYY"),
              });
            }
          }
        } catch (error) {
          console.error("Erreur lors du traitement de la date:", error);
        }
      }

      return suggestions;
    },

    async autoriserDepassement() {
      try {
        if (
          !this.taskEnCours ||
          !this.dateDestination ||
          !this.ressourceDestination
        ) {
          throw new Error("Informations manquantes");
        }

        if (
          !this.checkRightsBeforeAction(
            this.ressourceDestination.id,
            this.taskEnCours
          )
        ) {
          return;
        }

        const newStartDate = moment(this.dateDestination).startOf("day");
        const newEndDate = moment(this.dateDestination)
          .add(
            moment(this.taskEnCours.carte.assignement.dateFin).diff(
              moment(this.taskEnCours.carte.assignement.dateDebut),
              "days"
            ),
            "days"
          )
          .endOf("day");

        const updateData = {
          assignement: {
            assigned: true,
            assignedTo: this.ressourceDestination.name,
            dateDebut: newStartDate.format("YYYY-MM-DD HH:mm:ss"),
            dateFin: newEndDate.format("YYYY-MM-DD HH:mm:ss"),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
            forceDepassement: true,
          },
        };

        await this.updateTaskAssignment(
          this.taskEnCours,
          this.ressourceDestination,
          newStartDate,
          newEndDate
        );

        this.showLimitAlert = false;
        this.cleanupAfterAssignment();
      } catch (error) {
        console.error("Erreur lors du dépassement:", error);
        this.$nError("Erreur lors du déplacement de la tâche");
        this.showLimitAlert = false;
      }
    },

    cleanupAfterAssignment() {
      this.taskEnCours = null;
      this.dateDestination = null;
      this.ressourceDestination = null;
      this.datesSuggestions = [];
      this.clearCaches();
    },
    async plannifToDate(nouvelleDate) {
      try {
        if (this.taskEnCours) {
          // Calculer la durée originale en jours
          const originalDuration = moment(
            this.taskEnCours.carte.assignement.dateFin
          ).diff(moment(this.taskEnCours.carte.assignement.dateDebut), "days");

          // Nouvelle date de début (à midi)
          const newStartDate = moment(nouvelleDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          // Nouvelle date de fin en conservant la même durée (à midi aussi)
          const newEndDate = moment(nouvelleDate)
            .add(originalDuration, "days")
            .format("YYYY-MM-DD HH:mm:ss");

          // Procéder à la mise à jour de la tâche
          const response = await DeveloppementService.assignTask(
            this.taskEnCours._id,
            {
              assignement: {
                ...this.taskEnCours.carte.assignement,
                dateDebut: newStartDate,
                dateFin: newEndDate,
              },
            }
          );

          if (response) {
            // Mettre à jour la tâche localement
            const taskIndex = this.taskSupportVision.findIndex(
              (t) => t._id === this.taskEnCours._id
            );
            if (taskIndex !== -1) {
              this.$set(this.taskSupportVision, taskIndex, response.data);
            }
          }
        }

        // Fermer la modale et nettoyer
        this.showLimitAlert = false;
        this.cleanupAfterAssignment();

        // Rafraîchir les données
        this.resetSelection();
        await this.refreshCalendarData();

        this.$nInfo("Tâche planifiée avec succès");
      } catch (error) {
        console.error("Erreur lors de la planification:", error);
        this.$nError("Erreur lors de la planification de la tâche");
      }
    },

    formatDurationToHourMinutes(duration) {
      if (!duration && duration !== 0) return "";

      const hours = Math.floor(duration);
      const minutes = Math.round((duration - hours) * 60);

      if (minutes === 0) {
        return `${hours}h`;
      } else if (hours === 0) {
        return `${minutes}min`;
      }

      return `${hours}h${minutes}`;
    },

    async calculateOptimalDistribution(
      taskId,
      resource,
      startDate,
      totalHours
    ) {
      const distribution = {
        success: false,
        dates: [],
        message: "",
      };

      try {
        const DAILY_LIMIT = 7; // Limite stricte de 7h par jour
        let remainingHours = totalHours;
        let currentDate = moment(startDate);
        const maxDaysToCheck = 30;
        let daysChecked = 0;

        while (remainingHours > 0 && daysChecked < maxDaysToCheck) {
          // Vérifier si c'est un jour ouvrable
          if (this.isWorkingDay(currentDate)) {
            // Obtenir la charge existante pour ce jour
            const dayWorkload = this.getDailyWorkload(
              resource,
              currentDate.toDate()
            );

            // Ne considérer que les heures des tâches sans forceDepassement
            const baseHours = dayWorkload.details
              .filter((task) => !task.isForceDepassement)
              .reduce((sum, task) => sum + task.hours, 0);

            const availableHours = DAILY_LIMIT - baseHours;

            if (availableHours > 0) {
              const hoursToAssign = Math.min(remainingHours, availableHours);

              distribution.dates.push({
                date: currentDate.clone(),
                hours: hoursToAssign,
                totalDayHours: baseHours + hoursToAssign,
              });

              remainingHours -= hoursToAssign;
            }
          }

          currentDate.add(1, "day");
          daysChecked++;
        }

        if (remainingHours <= 0) {
          distribution.success = true;
          distribution.message = "Distribution optimale trouvée";

          // Formater les dates pour l'assignement
          const firstDate = distribution.dates[0].date;
          const lastDate =
            distribution.dates[distribution.dates.length - 1].date;

          distribution.assignement = {
            dateDebut: firstDate.format("YYYY-MM-DD HH:mm:ss"),
            dateFin: lastDate.format("YYYY-MM-DD HH:mm:ss"),
            hoursPerDay: distribution.dates.map((d) => ({
              date: d.date.format("YYYY-MM-DD"),
              hours: d.hours,
            })),
          };
        } else {
          distribution.message = `Impossible de distribuer ${remainingHours}h restantes dans les ${maxDaysToCheck} prochains jours sans dépasser 7h/jour`;
        }

        return distribution;
      } catch (error) {
        console.error("Erreur dans calculateOptimalDistribution:", error);
        return {
          success: false,
          message: "Erreur lors du calcul de la distribution",
          error: error.message,
        };
      }
    },

    async procederAssignation(task, resource, date) {
      try {
        // Vérifier si task et task.carte existent
        if (!task || !task.carte) {
          throw new Error("Tâche invalide");
        }

        // Calculer la durée en jours
        const originalDuration = task.carte.assignement
          ? moment(task.carte.assignement.dateFin).diff(
              moment(task.carte.assignement.dateDebut),
              "days"
            )
          : 0; // Valeur par défaut si pas d'assignement

        // Définir la nouvelle date de début
        const newStartDate = moment(date).startOf("day");

        // Calculer la nouvelle date de fin
        const newEndDate = moment(date)
          .startOf("day")
          .add(originalDuration || 0, "days") // Utiliser 0 si originalDuration est undefined
          .endOf("day");

        // Créer un nouvel assignement si nécessaire
        const updatedAssignement = {
          assignedTo: resource.name,
          dateDebut: newStartDate.toDate(),
          dateFin: newEndDate.toDate(),
          dateAssignement: moment().toDate(),
          assigned: true,
          // Conserver les propriétés existantes de l'assignement s'il existe
          ...(task.carte.assignement || {}),
        };

        const updatedTask = {
          ...task,
          carte: {
            ...task.carte,
            assignement: updatedAssignement,
          },
        };

        // Préparer les données pour la base de données
        const dataToSend = {
          assignement: {
            assigned: true,
            assignedTo: resource.name,
            dateDebut: newStartDate.format("YYYY-MM-DD HH:mm:ss"),
            dateFin: newEndDate.format("YYYY-MM-DD HH:mm:ss"),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        };

        // Enregistrer dans la base de données
        await this.saveTaskToDB(updatedTask);

        // Mettre à jour l'état local
        const taskIndex = this.taskSupportVision.findIndex(
          (t) => t._id === task._id
        );
        if (taskIndex !== -1) {
          this.$set(this.taskSupportVision, taskIndex, updatedTask);
        }

        // Forcer la mise à jour de la vue
        this.clearCaches();
        this.updateCalendarView();

        this.$nInfo("Tâche assignée avec succès");

        return updatedTask;
      } catch (error) {
        console.error("Erreur lors de l'assignation:", error);
        this.$nError("Erreur lors de l'assignation de la tâche");
        throw error;
      }
    },

    saveTaskToDB: debounce(async function(task) {
      try {
        const dataToSend = {
          assignement: {
            assigned: true,
            assignedTo: task.carte.assignement.assignedTo,
            dateDebut: moment(task.carte.assignement.dateDebut).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateFin: moment(task.carte.assignement.dateFin).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        };

        const response = await DeveloppementService.assignTask(
          task._id,
          dataToSend
        );
        return response;
      } catch (error) {
        console.error("Erreur lors de la sauvegarde:", error);
        throw error;
      }
    }, 0),
    async refreshCalendarData() {
      try {
        // Récupérer les données à jour depuis le serveur
        const response = await DeveloppementService.getDev();

        if (response?.data) {
          // Mettre à jour les données locales
          this.$store.commit("setTaskSupportVision", response.data);

          // Vider tous les caches
          this.workloadCache = {};
          this.eventsCache = {};
          this.dragCache = null;

          // Mettre à jour l'affichage
          this.updateVisibleWeeks();
          this.updateVisibleMonths();

          // Force update du composant
          this.$nextTick(() => {
            this.updateCalendarView();
            this.$forceUpdate();
          });
        }
      } catch (error) {
        console.error("Erreur lors du rafraîchissement des données:", error);
        this.$nError("Erreur lors du rafraîchissement des données");
      }
    },

    updateEstimatedDatesPosition() {
      const resourceGrid = this.$refs.resourcesGrid;
      const estimatedDatesSection = this.$el.querySelector(".info-timeline");

      if (resourceGrid && estimatedDatesSection) {
        const scrollLeft = resourceGrid.scrollLeft;
        estimatedDatesSection.style.transform = `translateX(${scrollLeft}px)`;
      }
    },

    onResourcesGridScroll(event) {
      if (!this.isScrolling) {
        this.isScrolling = true;

        // Synchroniser le scroll vertical de resourceNames avec resourcesGrid
        this.$refs.resourceNames.scrollTop = event.target.scrollTop;

        // Mise à jour position des dates estimées
        this.updateEstimatedDatesPosition();

        this.$nextTick(() => {
          this.isScrolling = false;
        });
      }
    },

    // Nouvelle méthode pour le scroll des ressources
    onResourceNamesScroll(event) {
      if (!this.isScrolling) {
        this.isScrolling = true;

        // Synchroniser le scroll vertical de resourcesGrid avec resourceNames
        this.$refs.resourcesGrid.scrollTop = event.target.scrollTop;

        this.$nextTick(() => {
          this.isScrolling = false;
        });
      }
    },
    showTooltip(event, e) {
      this.tooltipEvent = event;
      this.tooltipVisible = true;
      this.tooltipX = e.clientX + 10;
      this.tooltipY = e.clientY + 10;
    },
    showTooltipWithoutDate(task, e) {
      this.tooltipEvent = task;
      this.tooltipVisible = true;
      this.tooltipX = e.clientX + 10;
      this.tooltipY = e.clientY + 10;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    // Gestion du double-clic
    onCellDoubleClick(resource, date, event) {
      if (!this.$store.getters.canDoubleClick(resource.id)) {
        this.$nError("Vous n'avez pas les droits pour créer une tâche");
        return;
      }

      // Vérifie si la date est un weekend
      const dayOfWeek = moment(date).day();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        this.weekendDates = [moment(date).format("DD/MM/YYYY")];
        this.showWeekendAlert = true; // Changer ici - utiliser showWeekendAlert au lieu de showLimitAlert
        return;
      }

      event?.stopPropagation();
      this.resetSelection();

      const cell = event.target.closest(".day-cell");
      const row = cell.closest(".resource-row");
      if (!cell || !row) return;

      // Initialiser la sélection
      this.selectedResource = resource;
      this.selectionStart = moment(date).toDate();
      this.selectionEnd = moment(date).toDate();
      this.isSelecting = true;

      // Ajouter un attribut data-selecting à la ligne
      row.setAttribute("data-selecting", "true");

      // Créer l'élément de sélection dans la ligne
      this.createSelectionElement(cell, row);
      this.setupSelectionListeners();
    },

    initializeSelection(resource, date, event) {
      // Vérifier les droits
      if (!this.canDoubleClick(resource.id)) return;

      const cell = event.target.closest(".day-cell");
      if (!cell) return;

      this.selectedResource = resource;
      this.selectionStart = moment(date).toDate();
      this.selectionEnd = moment(date).toDate();
      this.isSelecting = true;

      // Créer l'élément de sélection visuelle
      this.createSelectionElement(cell);

      // Ajouter les écouteurs
      this.setupSelectionListeners();
    },

    closeCreateModale() {
      this.showCreateTaskModal = false;
      this.cancelSelection();
    },

    handleSelectionDrag(event) {
      // Annuler sur clic droit pendant la sélection
      if (event.button === 2 || event.buttons === 2) {
        event.preventDefault();
        this.cancelSelection();
        return;
      }

      if (!this.isSelecting || !this.selectionElement) return;

      const cell = event.target.closest(".day-cell");
      const row = cell?.closest(".resource-row");

      // Vérifier que nous sommes toujours sur la même ressource
      if (!cell || !row || row.dataset.resourceId !== this.selectedResource.id)
        return;

      const date = this.getDateFromCell(cell);
      if (!date) return;

      // Si la sélection commence un jour ouvrable, on doit gérer les weekends
      const selectionStartsOnWorkday = this.isWorkingDay(this.selectionStart);

      if (selectionStartsOnWorkday) {
        // Trouver la date la plus proche qui n'est pas un weekend
        let adjustedDate = moment(date);
        const startDate = moment(this.selectionStart);
        const direction = adjustedDate.isAfter(startDate) ? 1 : -1;

        // Si la date actuelle est un weekend, trouver le prochain/précédent jour ouvrable
        while (!this.isWorkingDay(adjustedDate)) {
          adjustedDate.add(direction, "days");
        }

        // Mettre à jour la date de fin
        this.selectionEnd = adjustedDate.toDate();
      } else {
        // Si on commence un weekend, garder le comportement original
        this.selectionEnd = date;
      }

      // Trouver la cellule initiale
      const startIdx = this.getDayIndex(moment(this.selectionStart));
      const endIdx = this.getDayIndex(moment(this.selectionEnd));

      // Calculer la nouvelle position et largeur
      const minIdx = Math.min(startIdx, endIdx);
      const maxIdx = Math.max(startIdx, endIdx);
      const cells = Array.from(row.querySelectorAll(".day-cell"));

      // Ajuster visuellement la sélection pour exclure les weekends
      let visualStartIdx = minIdx;
      let visualEndIdx = maxIdx;

      if (selectionStartsOnWorkday) {
        for (let i = minIdx; i <= maxIdx; i++) {
          const cellDate = this.getDateFromCell(cells[i]);
          if (this.isWorkingDay(cellDate)) {
            visualStartIdx = Math.min(visualStartIdx, i);
            visualEndIdx = Math.max(visualEndIdx, i);
          }
        }
      }

      const startCell = cells[visualStartIdx];
      const endCell = cells[visualEndIdx];

      const width =
        endCell.offsetLeft + endCell.offsetWidth - startCell.offsetLeft;

      // Mettre à jour le style de l'élément de sélection
      Object.assign(this.selectionElement.style, {
        left: `${startCell.offsetLeft}px`,
        width: `${width}px`,
      });
    },

    getInitialCell() {
      const initialDate = moment(this.selectionStart).format("YYYY-MM-DD");
      const row = this.$refs.resourcesGrid.querySelector(
        `[data-resource-id="${this.selectedResource.id}"]`
      );
      return Array.from(row.querySelectorAll(".day-cell")).find((cell) => {
        const cellDate = this.getDateFromCell(cell);
        return moment(cellDate).format("YYYY-MM-DD") === initialDate;
      });
    },

    getDateFromCell(cell) {
      const dayIndex = Array.from(cell.parentNode.children).indexOf(cell);
      return this.flattenedDays[dayIndex]?.date;
    },

    // Classes conditionnelles
    getDayCellClasses(date, resourceId) {
      return {
        weekend: this.isWeekend(date),
        today: this.isToday(date),
        past: this.isPast(date),
        "can-edit": this.$store.getters.canDoubleClick(resourceId),
        disabled: !this.$store.getters.canDrop(resourceId),
      };
    },

    setupSelectionListeners() {
      // Ajouter un écouteur pour le mousedown pour détecter le clic droit
      document.addEventListener("mousedown", this.handleMouseDown);
      document.addEventListener("mousemove", this.handleSelectionDrag);
      document.addEventListener("mouseup", this.onSelectionEnd);
      document.addEventListener("mouseleave", this.onSelectionEnd);
      document.addEventListener("keydown", this.handleKeyPress);
      document.addEventListener("contextmenu", this.handleContextMenu);
    },

    handleKeyPress(event) {
      if (event.key === "Escape" && this.isSelecting) {
        event.preventDefault();
        this.cancelSelection();
      }
    },

    handleContextMenu(event) {
      if (this.isSelecting) {
        event.preventDefault();
      }
    },

    handleMouseDown(event) {
      if (event.button === 2 && this.isSelecting) {
        event.preventDefault();
        this.cancelSelection();
      }
    },

    cancelSelection() {
      if (this.selectionElement) {
        this.selectionElement.style.transition = "opacity 0.15s ease";
        this.selectionElement.style.opacity = "0";

        setTimeout(() => {
          this.resetSelection();
        }, 150);
      } else {
        this.resetSelection();
      }
    },

    checkForWeekends(startDate, endDate) {
      const weekends = [];
      let currentDate = moment(startDate);
      const end = moment(endDate);

      while (currentDate.isSameOrBefore(end)) {
        if (currentDate.day() === 0 || currentDate.day() === 6) {
          weekends.push(currentDate.format("DD/MM/YYYY"));
        }
        currentDate.add(1, "day");
      }

      return weekends;
    },

    // Modifier onSelectionEnd()
    onSelectionEnd() {
      if (!this.isSelecting) return;
      document.body.style.cursor = "";
      document.removeEventListener("mousemove", this.handleSelectionDrag);
      document.removeEventListener("mouseup", this.onSelectionEnd);
      document.removeEventListener("mouseleave", this.onSelectionEnd);

      this.isSelecting = false;

      if (this.selectedResource && this.selectionStart && this.selectionEnd) {
        const startDate = moment.min(
          moment(this.selectionStart),
          moment(this.selectionEnd)
        );
        const endDate = moment.max(
          moment(this.selectionStart),
          moment(this.selectionEnd)
        );

        // Vérifier les weekends
        const weekends = this.checkForWeekends(startDate, endDate);

        if (weekends.length > 0) {
          // Il y a des weekends, afficher l'alerte
          this.tempDateRange = {
            start: startDate.toDate(),
            end: endDate.toDate(),
          };
          this.tempSelectedResource = this.selectedResource;
          this.weekendDates = weekends;
          this.showWeekendAlert = true;
        } else {
          // Pas de weekends, ouvrir directement la modale de création
          this.selectedDateRange = {
            start: startDate.toDate(),
            end: endDate.toDate(),
            skipWeekends: false,
          };
          this.selectedResourceForTask = this.selectedResource;
          this.showCreateTaskModal = true;
        }
      }
    },

    // Nouvelle méthode pour gérer le choix de l'utilisateur
    handleWeekendChoice() {
      let adjustedStart, adjustedEnd;

      switch (this.weekendChoice) {
        case "skip":
          ({
            start: adjustedStart,
            end: adjustedEnd,
          } = this.adjustToWorkingDays(
            this.tempDateRange.start,
            this.tempDateRange.end
          ));
          this.selectedDateRange = {
            start: adjustedStart.toDate(),
            end: adjustedEnd.toDate(),
            skipWeekends: true, // Ajouter ce flag
          };
          this.selectedResourceForTask = this.tempSelectedResource;
          this.showCreateTaskModal = true;
          break;

        case "include":
          this.selectedDateRange = {
            ...this.tempDateRange,
            skipWeekends: false, // Explicitement indiquer que nous incluons les weekends
          };
          this.selectedResourceForTask = this.tempSelectedResource;
          this.showCreateTaskModal = true;
          break;

        case "change":
          this.$nInfo("Veuillez sélectionner de nouvelles dates sans weekends");
          break;
      }

      this.showWeekendAlert = false;
      this.tempDateRange = null;
      this.tempSelectedResource = null;
    },
    adjustToWorkingDays(startDate, endDate) {
      let adjustedStart = moment(startDate);
      let adjustedEnd = moment(endDate);
      let workingDays = [];

      // Collecter tous les jours ouvrables entre les dates
      let currentDate = moment(adjustedStart);
      while (currentDate.isSameOrBefore(adjustedEnd)) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          workingDays.push(currentDate.clone());
        }
        currentDate.add(1, "day");
      }

      // S'il n'y a pas de jours ouvrables, prendre le prochain jour ouvrable
      if (workingDays.length === 0) {
        currentDate = moment(adjustedStart);
        while (currentDate.day() === 0 || currentDate.day() === 6) {
          currentDate.add(1, "day");
        }
        return {
          start: currentDate,
          end: currentDate,
        };
      }

      // Retourner le premier et le dernier jour ouvrable
      return {
        start: workingDays[0],
        end: workingDays[workingDays.length - 1],
      };
    },
    getDateFromEvent(event) {
      try {
        // Get the cell element from the event
        const cell = event.target.closest(".day-cell");
        if (!cell) return null;

        // Find the index of this cell in the flattened days array
        const cells = Array.from(document.querySelectorAll(".day-cell"));
        const cellIndex = cells.indexOf(cell);

        if (cellIndex === -1) return null;

        // Get the date from the flattenedDays array
        const date = this.flattenedDays[cellIndex]?.date;
        return date || null;
      } catch (error) {
        console.error("Error in getDateFromEvent:", error);
        return null;
      }
    },
    createSelectionElement(cell, row) {
      if (this.selectionElement) {
        this.selectionElement.remove();
      }

      this.selectionElement = document.createElement("div");

      const cellRect = cell.getBoundingClientRect();
      const rowRect = row.getBoundingClientRect();

      Object.assign(this.selectionElement.style, {
        position: "absolute",
        backgroundColor: "rgba(123, 31, 162, 0.2)",
        border: "1px solid rgb(123, 31, 162)",
        pointerEvents: "none",
        zIndex: 1000,
        left: `${cell.offsetLeft}px`,
        width: `${cell.offsetWidth}px`,
        height: `${row.offsetHeight - 2}px`, // -2 pour tenir compte des bordures
      });

      // Important: Ajouter l'élément à la ligne plutôt qu'à la grille
      row.style.position = "relative";
      row.appendChild(this.selectionElement);

      // Ajouter un petit message d'aide
      const helpTip = document.createElement("div");
      Object.assign(helpTip.style, {
        position: "absolute",
        bottom: "0px",
        left: "50%",
        transform: "translateX(-50%)",
        whiteSpace: "nowrap",
        fontSize: "11px",
        color: "#666",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        padding: "2px 6px",
        borderRadius: "3px",
        pointerEvents: "none",
      });
      helpTip.textContent = "Échap ou clic droit pour annuler";
      this.selectionElement.appendChild(helpTip);
    },
    onDocumentMouseMove(event) {
      // Empêcher la sélection de texte pendant le drag
      event.preventDefault();
    },
    updateSelectionPosition() {
      if (!this.selectionElement || !this.selectionStart || !this.selectionEnd)
        return;

      const startDay = moment(this.selectionStart).startOf("day");
      const endDay = moment(this.selectionEnd).startOf("day");

      const cellWidth = this.getCellWidth();
      const startIndex = this.getDayIndex(startDay);
      const endIndex = this.getDayIndex(endDay);

      const left = startIndex * cellWidth;
      const width = (endIndex - startIndex + 1) * cellWidth;

      this.selectionElement.style.left = `${left}px`;
      this.selectionElement.style.width = `${width}px`;
    },
    onCellMouseMove(date, event) {
      if (!this.isSelecting) return;

      const cellRect = event.target.getBoundingClientRect();
      const mouseX = event.clientX - cellRect.left;
      const cellWidth = cellRect.width;
      const dayFraction = mouseX / cellWidth;

      const hoverTime = moment(date)
        .startOf("day")
        .add(dayFraction * 24, "hours");

      this.selectionEnd = hoverTime.toDate();
      this.updateSelectionPosition();
    },
    onCellMouseUp(event) {
      if (!this.isSelecting) return;

      // Arrêter la propagation pour éviter que le clic global ne déclenche resetSelection
      event?.stopPropagation();

      this.isSelecting = false;
      if (this.selectedResource && this.selectionStart && this.selectionEnd) {
        this.selectedDateRange = {
          start: moment
            .min(moment(this.selectionStart), moment(this.selectionEnd))
            .toDate(),
          end: moment
            .max(moment(this.selectionStart), moment(this.selectionEnd))
            .toDate(),
        };
        this.selectedResourceForTask = this.selectedResource;
        this.showCreateTaskModal = true;
      }

      document.removeEventListener("mousemove", this.onDocumentMouseMove);
      document.removeEventListener("mouseup", this.onDocumentMouseUp);
    },
    onDocumentMouseUp() {
      this.onCellMouseUp();
    },
    async onTaskCreated(newTask) {
      try {
        if (this.selectedDateRange && this.selectedResourceForTask) {
          const startDate = moment(this.selectedDateRange.start);
          const nouvellesHeures = newTask.carte.duree?.duree || 0;
          const chargeJournaliere = this.calculateChargeJournaliere(
            this.selectedResourceForTask,
            startDate.toDate()
          );

          if (
            chargeJournaliere + nouvellesHeures > this.ressourceLimite &&
            !newTask.carte.assignement?.forceDepassement
          ) {
            // La logique existante pour la distribution reste la même
            const distribution = await this.calculateOptimalDistribution(
              newTask._id,
              this.selectedResourceForTask,
              startDate.toDate(),
              nouvellesHeures
            );

            if (distribution.success) {
              newTask.carte.assignement = {
                ...newTask.carte.assignement,
                dateDebut: distribution.assignement.dateDebut,
                dateFin: distribution.assignement.dateFin,
                assignedTo: this.selectedResourceForTask.name,
              };

              this.taskSupportVision.push(newTask);
              this.clearCaches();
              await this.refreshCalendarData();
              this.closeCreateDevModal();
              this.$nInfo("Nouvelle tâche créée et répartie");
              return;
            }
          }

          // Gestion normale de la tâche
          this.taskSupportVision.push(newTask);
          this.clearCaches();
          await this.refreshCalendarData();
          this.closeCreateDevModal();
          this.$nInfo("Nouvelle tâche créée avec succès");
        }
      } catch (error) {
        console.error("Erreur lors de la création de la tâche:", error);
        this.$nError("Erreur lors de la création de la tâche");
      }
    },
    resetSelection() {
      if (this.selectionElement) {
        this.selectionElement.remove();
        this.selectionElement = null;
      }

      this.selectionStart = null;
      this.selectionEnd = null;
      this.selectedResource = null;
      this.selectedDateRange = null;
      this.selectedResourceForTask = null;
      this.isSelecting = false;

      // Supprimer tous les écouteurs
      document.removeEventListener("mousedown", this.handleMouseDown);
      document.removeEventListener("mousemove", this.handleSelectionDrag);
      document.removeEventListener("mouseup", this.onSelectionEnd);
      document.removeEventListener("mouseleave", this.onSelectionEnd);
      document.removeEventListener("keydown", this.handleKeyPress);
      document.removeEventListener("contextmenu", this.handleContextMenu);
    },
    closeCreateDevModal() {
      this.showCreateTaskModal = false;
      this.resetSelection();
    },
    onCellMouseEnter(date, event) {
      if (!this.selectionStart) return;

      const cellRect = event.target.getBoundingClientRect();
      const mouseX = event.clientX - cellRect.left;
      const cellWidth = cellRect.width;
      const dayFraction = mouseX / cellWidth;

      const hoverTime = moment(date)
        .startOf("day")
        .add(dayFraction * 24, "hours");

      this.selectionEnd = hoverTime.toDate();
    },
    getDayIndex(date) {
      return this.flattenedDays.findIndex((day) =>
        moment(day.date).isSame(date, "day")
      );
    },
    calculateSelectionStyle() {
      if (!this.selectionStart || !this.selectionEnd || !this.selectedResource)
        return {};

      const startDay = moment(this.selectionStart).startOf("day");
      const endDay = moment(this.selectionEnd).startOf("day");

      const startIndex = this.getDayIndex(startDay);
      const endIndex = this.getDayIndex(endDay);

      // Obtenir la largeur réelle d'une cellule
      const cellWidth = this.getCellWidth();

      // Calculer les offsets en tenant compte de l'heure exacte
      const startOffset =
        (this.selectionStart - startDay) / (24 * 60 * 60 * 1000);
      const endOffset = (this.selectionEnd - endDay) / (24 * 60 * 60 * 1000);

      const left = startIndex * cellWidth + startOffset * cellWidth;
      const width =
        (endIndex - startIndex) * cellWidth +
        (endOffset - startOffset) * cellWidth;

      return {
        position: "absolute",
        left: `${left}px`,
        width: `${width}px`,
        height: "100%",
        backgroundColor: "rgba(0, 123, 255, 0.3)",
        pointerEvents: "none",
      };
    },
    getCellWidth() {
      const cell = this.$el.querySelector(".day-cell");
      return cell ? cell.offsetWidth : 40;
    },
    handleGlobalClick(event) {
      // Vérifier si on clique sur une cellule ou sur la zone de sélection
      const isClickOnCell = event.target.closest(".day-cell");
      const isClickOnSelection = event.target.closest(".selection-overlay");

      // Si on ne clique ni sur une cellule ni sur la sélection, on reset
      if (!isClickOnCell && !isClickOnSelection) {
        this.resetSelection();
      }
    },
    async onCarteCreated(carte) {
      try {
        // Étape 2 : Assigner la tâche
        const assignmentData = {
          assignement: {
            assigned: true,
            assignedTo: this.selectedResourceForTask.name,
            dateDebut: moment(this.selectedDateRange.start).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateFin: moment(this.selectedDateRange.end).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateAssignement: moment().format("YYYY-MM-DD HH:mm:ss"),
          },
        };

        const assignedTask = await DeveloppementService.assignTask(
          carte._id,
          assignmentData
        );

        // Ajouter la tâche assignée à la liste des tâches
        if (assignedTask && assignedTask.data) {
          this.taskSupportVision.push(assignedTask.data);
        }

        this.closeCreateDevModal();
        this.$nInfo("Nouvelle tâche créée et assignée avec succès");
        await this.refreshCalendarData();
      } catch (error) {
        console.error(
          "Erreur lors de la création ou de l'assignation de la tâche:",
          error
        );
        this.$nError(
          "Erreur lors de la création ou de l'assignation de la tâche"
        );
      }
    },
    onUnassignedDragOver(event) {
      if (!this.isEncadrement) return;
      event.preventDefault();
    },

    onUnassignedDragEnter(event) {
      if (!this.isEncadrement) return;
      const container = event.target.closest(".unassigned-tasks-container");
      if (container) {
        container.classList.add("unassigned-drag-over");
      }
    },

    onUnassignedDragLeave(event) {
      const container = event.target.closest(".unassigned-tasks-container");
      if (container) {
        container.classList.remove("unassigned-drag-over");
      }
    },

    async onUnassignedDrop(event) {
      try {
        if (!this.isEncadrement) return;
        event.preventDefault();

        let taskId;
        try {
          const dragData = JSON.parse(event.dataTransfer.getData("text/plain"));
          taskId = dragData.taskId;
        } catch {
          taskId = this.dragCache?.taskId;
        }

        if (!taskId) return;

        const task = this.taskSupportVision.find((t) => t._id === taskId);
        if (!task?.carte) return;

        // Reset de toutes les propriétés de date et d'assignation
        const resetData = {
          assignement: {
            assigned: false,
            assignedTo: null,
            dateDebut: null,
            dateFin: null,
            dateAssignement: null,
          },
          datePrevisionnel: {
            previsionDate: null,
            dateCreation: null,
          },
        };

        try {
          // Réinitialiser l'assignement et la date prévisionnelle
          await DeveloppementService.unassignTask(taskId);
          const response = await DeveloppementService.addDatePrevisionnel(
            taskId,
            {
              data: resetData.datePrevisionnel,
            }
          );

          // Mise à jour locale
          const taskIndex = this.taskSupportVision.findIndex(
            (t) => t._id === taskId
          );
          if (taskIndex !== -1) {
            // Mise à jour complète de la tâche
            const updatedTask = {
              ...task,
              carte: {
                ...task.carte,
                ...resetData,
              },
            };
            this.$set(this.taskSupportVision, taskIndex, updatedTask);
          }

          // Rafraîchissement immédiat des données
          await this.refreshCalendarData();

          // Force update de l'interface
          this.$nextTick(() => {
            // Vider tous les caches
            this.workloadCache = {};
            this.eventsCache = {};
            this.dragCache = null;

            // Mettre à jour les vues
            this.updateVisibleWeeks();
            this.updateVisibleMonths();
            this.updateCalendarView();
            this.$forceUpdate();
          });

          this.$nInfo("Tâche retirée du planning");
        } catch (error) {
          console.error(
            "Erreur lors de la réinitialisation de la tâche:",
            error
          );
          throw error;
        }
      } catch (error) {
        console.error(
          "Erreur lors du déplacement vers la zone non assignée:",
          error
        );
        this.$nError("Erreur lors du déplacement de la tâche");
      } finally {
        const container = event.target.closest(".unassigned-tasks-container");
        if (container) {
          container.classList.remove("unassigned-drag-over");
        }
      }
    },
    updateCalendarView() {
      this.updateVisibleWeeks();
      this.updateVisibleMonths();
    },
    clearCaches() {
      this.workloadCache = {};
      this.eventsCache = {};
      this.dragCache = null;
    },
    async loadData() {
      this.updateVisibleWeeks();
      this.updateVisibleMonths();
      const clientsList = await ClientService.getClientList();
      const baseList = await BasesService.getBasesList();
      this.$store.commit("setClientList", clientsList);
      this.$store.commit("setBaseList", baseList);
      // Charger les couleurs
      const resParams = await ParametrageService.getParametrages();
      const parametrages = resParams.data;

      // Créer un objet qui map les types avec leurs couleurs
      this.devTypeColors = {};
      parametrages.forEach((param) => {
        if (
          param.parametrage &&
          param.parametrage.libelle &&
          param.parametrage.couleur
        ) {
          this.devTypeColors[param.parametrage.libelle] =
            param.parametrage.couleur;
        }
      });
    },
    isDataReady() {
      // Simplement vérifier que teams existe car il est initialisé dans initializeRights
      return Boolean(this.teams);
    },
    async initializeComponent() {
      try {
        // Attendre l'initialisation des droits
        await this.$store.dispatch("initializeRights", this.informations.id);
        // Vérifier si les droits sont correctement chargés
        const userRights = this.$store.state.userRights;
        if (!userRights) {
          throw new Error("Rights not properly initialized");
        }

        await this.loadData();

        if (!this.canViewPlanning) {
          this.$nError("Vous n'avez pas les droits pour accéder au planning");
          return;
        }
      } catch (error) {
        console.error("Erreur lors de l'initialisation:", error);
        this.$nError("Erreur lors du chargement des droits");
      }
    },
    updateHeight() {
      const calendrierRessource = this.$refs.calendrierRessources;
      const resourceNames = this.$refs.resourceNames;

      if (calendrierRessource && resourceNames) {
        // Calculer la hauteur disponible
        const windowHeight = window.innerHeight;
        const headerHeight = 50; // Hauteur de votre header
        const availableHeight = windowHeight - headerHeight;

        // Appliquer la hauteur calculée
        calendrierRessource.style.height = `${availableHeight}px`;
        resourceNames.style.height = `${availableHeight}px`;
      }
    },
  },
  async created() {
    try {
      if (this.informations?.id) {
        await this.$store.dispatch("initializeRights", this.informations.id);
      }
      await this.initializeComponent();
    } catch (error) {
      console.error("Erreur initialisation:", error);
    }
  },
  async mounted() {
    window.addEventListener("resize", this.updateHeight);
    this.updateHeight(); // Appel initial
    // await this.loadUserTeamRights();
    await this.initializeComponent();
    await this.handleZoomChange();

    if (!this.canViewPlanning) {
      this.$nError("Vous n'avez pas les droits pour accéder au planning");
      return;
    }
    await this.loadData();
    // écouteur pour la touche Echap
    document.addEventListener("keydown", this.handleEscapeKey);
    this.$nextTick(() => {
      this.updateEstimatedDatesPosition();
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const element = entry.target;
            if (entry.isIntersecting) {
              element.classList.remove("is-sticky");
            } else {
              element.classList.add("is-sticky");
            }
          });
        },
        { threshold: [1] }
      );

      document
        .querySelectorAll(".info-row, .info-timeline")
        .forEach((element) => observer.observe(element));
    });
    this.pinnedResourceId = localStorage.getItem("pinnedResourceId");
  },
  watch: {
    menu(val) {
      if (val) {
        this.$nextTick(() => {
          setTimeout(() => {
            const input = this.$refs.searchInput?.$el.querySelector("input");
            if (input) {
              input.focus();
            }
          }, 100);
        });
      }
    },
    currentDate() {
      this.clearCaches();
      this.updateCalendarView();
    },
    teams: {
      immediate: true,
      handler() {
        this.getTeamOptions();
      },
    },
    currentView(newValue) {
      localStorage.setItem("calendarView", newValue);
      this.clearCaches();
      this.updateCalendarView();
    },
    taskSupportVision: {
      deep: true,
      handler() {
        this.clearCaches();
        if (!this.updateScheduled) {
          this.updateScheduled = true;
          this.$nextTick(() => {
            this.updateCalendarView();
            this.updateScheduled = false;
          });
        }
      },
    },
    selectedTeam(newValue) {
      this.$nextTick(() => {
        this.recalculateCalendarHeight();
      });
    },
  },
  beforeDestroy() {
    if (this.trackingDebounceTimer) {
      clearTimeout(this.trackingDebounceTimer);
    }
    // Nettoyer l'écouteur quand le composant est détruit
    document.removeEventListener("keydown", this.handleEscapeKey);
    document.removeEventListener("mousemove", this.handleSelectionDrag);
    // Nettoyer l'écouteur
    window.removeEventListener("resize", this.updateHeight);
  },

  handleEscapeKey(event) {
    if (event.key === "Escape") {
      this.resetSelection();
    }
  },
};
</script>

<style scoped>
/* Base layout */
.calendrier-ressources {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 170px);
  width: 100%; /* Ajout pour utiliser toute la largeur */
  border-left: 1px solid #e0e0e0;
  margin-left: -1px;
}
/* Ajuster la colonne des ressources */
.resource-names {
  border-right: none; /* Supprime la bordure droite dupliquée */
}

/* Ajuster l'alignement des cellules */
.grid-header {
  border-left: none;
}

.month-header:first-child,
.day-header:first-child {
  border-left: none;
}
/* Header styles */
.custom-header,
.custom-ressource-header {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}
.custom-ressource-header {
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  padding: 10px 15px;
  height: 50px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
}

nav span {
  margin-right: 15px;
  cursor: pointer;
  font-size: 14px;
}

.active {
  font-weight: bold;
  color: #673ab7;
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.controls button {
  background-color: #673ab7;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
}

.controls button:hover {
  background-color: #6a1b9a;
}

.view-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.view-selector select {
  padding: 5px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 13px;
  cursor: pointer;
}

/* Calendar container - Mise à jour */
.calendar-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  position: relative;
}

/* Resource names column - Mise à jour */
.resource-names {
  /* width: 183px;
  min-width: 183px; */
  /* en pourcentage */
  min-width: 11.4rem;
  flex-shrink: 0;
  overflow-y: auto;
  background-color: #f5f5f5;
  border-right: 1px solid #e0e0e0;
  position: relative;
}

.resource-menu {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  /* min-height: 140px; */
  display: flex;
  align-items: center;
  gap: 16px; /* Ajoute un espacement uniforme entre les éléments */
}
.menu-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  min-height: 44px;
  width: 100%;
  margin-top: 5px;
}
.v-list-item--active {
  background-color: rgba(123, 31, 162, 0.1);
}

/* Style pour le hover des éléments de la liste */
.v-list-item:hover {
  background-color: rgba(123, 31, 162, 0.05);
}

.resource-name {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  height: 80px;
  background-color: #f5f5f5;
  transition: height 0.2s ease, min-height 0.2s ease;
}
.resource-name:hover {
  background-color: rgba(123, 31, 162, 0.05);
  cursor: pointer;
}
.ressource-mini {
  padding: 10px 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  height: 80px;
  background-color: #f5f5f5;
  width: 30%;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  color: white;
  font-weight: bold;
  font-size: 14px;
}
.avatar-mini {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.name-role {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

/* Calendar grid - Mise à jour */
.calendar-grid {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 0; /* Important pour le flex */
}

.grid-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
}

/* Headers - Mise à jour */
.months-header,
.days-header {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  height: 50px;
  width: 100%;
}

.month-header {
  background-color: #e0e0e0 !important;
  text-align: center;
  padding: 16px 0;
  border-right: 1px solid #e0e0e0;
  font-weight: 600;
  font-size: 12px;
  color: #666;
  flex: 1;
  /* Augmenté de 40px à 60px */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  background-color: white;
}

.day-header {
  flex: 1;
  min-width: 3%;
  /* min-width: 60px; */
  /* Augmenté de 40px à 60px */
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  position: relative;
  background-color: white;
  box-sizing: border-box;
}

/* Resources grid - Mise à jour */
.resources-grid {
  flex: 1;
  overflow: auto;
  position: relative;
  width: 100%;
}

.resource-row {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  min-height: 80px;
  width: 100%;
}

.day-cell {
  flex: 1;
  min-width: 3%;
  /* min-width: 60px; */
  /* Augmenté de 40px à 60px */
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none; /* Empêche la sélection de texte pendant le drag */
}
/* Style pour indiquer que la cellule est sélectionnable */
.day-cell.can-edit:not(.weekend):not(.disabled):hover {
  background-color: rgba(123, 31, 162, 0.05);
  cursor: pointer;
}

/* Style pendant la sélection active */
.resource-row[data-selecting="true"]
  .day-cell:not(.weekend):not(.disabled):hover {
  background-color: rgba(123, 31, 162, 0.1);
}
/* Events container and events */
.day-cell-content {
  flex: 1;
  position: relative;
  height: calc(100% - 20px);
}

.resource-events-container {
  flex: 1;
  position: relative;
  height: 100%; /* Espace pour les événements */
  overflow-y: auto; /* Permet le scroll vertical */
  scrollbar-width: thin; /* Pour Firefox */
  padding: 2px;
  display: flex;
}

.event {
  display: flex;
  align-items: center;
  height: 20px; /* Hauteur réduite */
  margin: 1px 0;
  padding: 2px 4px;
  background-color: var(--event-color, #7b1fa2);
  color: white;
  border-radius: 3px;
  font-size: 11px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.event:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.event-title {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

/* States */
.weekend {
  background-color: #ebe9e9;
}

.today {
  background-color: #d1c4e9;
}

.past {
  background-color: #fafafa;
  opacity: 0.8;
}
.day-cell.weekend.past {
  background-color: #ebe9e9;
}

/* Workload gauge */
.workload-gauge {
  height: 18px; /* Hauteur fixe pour la jauge */
  margin-top: auto; /* Pousse la jauge vers le bas */
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  position: relative;
  z-index: 1;
}

.gauge-bar {
  height: 100%;
  transition: width 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.workload-low .gauge-bar {
  background-color: #4caf50;
}
.workload-medium .gauge-bar {
  background-color: #ff9800;
}
.workload-high .gauge-bar {
  background-color: #f44336;
}
.workload-critical .gauge-bar {
  background-color: #d32f2f;
}

.gauge-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: #666;
  line-height: 20px;
  z-index: 2;
}

/* Tooltip */
.tooltip {
  position: fixed;
  background-color: rgba(33, 33, 33, 0.95);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Scrollbar styles */
.resources-grid::-webkit-scrollbar,
.resource-names::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.resources-grid::-webkit-scrollbar-track,
.resource-names::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.resources-grid::-webkit-scrollbar-thumb,
.resource-names::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
:deep(.v-navigation-drawer--mini-variant) .custom-ressource-header .active {
  text-align: center;
  width: 100%;
}
/* Responsive styles */
@media (max-width: 1280px) {
  .day-header,
  .month-header,
  .day-cell {
    /* min-width: 55px;  */
    /* Augmenté de 35px à 55px */
  }

  .event {
    font-size: 10px;
    height: 18px;
  }

  .workload-gauge {
    height: 18px;
  }
}

@media (max-width: 1024px) {
  .day-header,
  .month-header,
  .day-cell {
    /* min-width: 50px; */
    /* Augmenté de 30px à 50px */
  }
}

@media (max-width: 768px) {
  .day-header,
  .month-header,
  .day-cell {
    /* min-width: 45px; */
    /* Augmenté de 25px à 45px */
  }
}

/* Ajuster le style des événements pour mieux utiliser l'espace */
.event {
  padding: 2px 6px; /* Augmenté le padding horizontal */
  height: 26px; /* Légèrement plus haut */
  line-height: 22px; /* Ajusté pour le centrage vertical */
  font-size: 12px; /* Légèrement plus grand */
}

/* Ajuster le numéro de semaine pour la nouvelle largeur */
.week-number {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 10px;
  color: #999;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 3px;
}

/* Ajuster les noms de jours pour la nouvelle largeur */
.day-name {
  font-size: 11px;
  margin-top: 2px;
}

.day-number {
  font-size: 13px;
  margin-top: 2px;
}

.v-select.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 40px;
}

.resource-name.info-row {
  height: 345px !important;
  gap: 2px;
}
.info-timeline {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  height: 293px !important;
  position: sticky;
  top: 0px; /* Même valeur que info-row */
  z-index: 5;
  transition: background-color 0.3s ease, border 0.3s ease;
}
.info-timeline.timeline-drag-over {
  background-color: rgba(123, 31, 162, 0.1);
  border: 2px dashed #673ab7;
}

.estimation-info {
  font-size: 0.8rem;
  color: #495057;
  text-align: center;
  /* padding: 6px; */
  white-space: pre-line;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Changé de center à flex-start */
  align-items: center;
  overflow-y: auto;
  max-height: 100%;
}

/* Style pour le scrollbar des cellules d'estimation */
.estimation-info::-webkit-scrollbar {
  width: 4px;
}

.estimation-info::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.estimation-info::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 4px;
}

.estimation-info::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* Style pour améliorer la lisibilité du contenu */
.estimation-info-content {
  /* padding: 4px; */
  width: 100%;
  text-align: center;
}

/* Style quand il y a du contenu */
.estimation-info:not(:empty) {
  background-color: rgba(123, 31, 162, 0.1);
  /* border-radius: 4px; */
  /* margin: 2px; */
  /* padding: 8px; */
}

.info-row {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  height: 200px !important; /* Même hauteur que info-timeline */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  position: sticky;
  top: 100px;
  z-index: 5;
}

.info-title {
  font-weight: 500;
  color: #495057;
  font-size: 0.9rem;
  text-align: center;
  padding: 14px;
  background-color: rgba(123, 31, 162, 0.1);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.info-title-mini {
  font-weight: 500;
  color: #495057;
  font-size: 0.9rem;
  text-align: center;
  /* padding: 10px; */
  /* background-color: rgba(123, 31, 162, 0.1); */
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.info-stats {
  font-size: 0.8rem;
  margin-top: 8px;
  color: #666;
}

.estimation-task {
  background-color: rgba(123, 31, 162, 0.2);
  border-radius: 4px;
  padding: 6px 8px;
  margin: 4px 0;
  cursor: grab;
  transition: all 0.2s ease;
  user-select: none;
}

.estimation-task:hover {
  background-color: rgba(123, 31, 162, 0.3);
  transform: translateY(-1px);
}

.estimation-task.dragging {
  opacity: 0.7;
  cursor: grabbing;
}

.estimation-task-details {
  font-size: 0.75rem;
  color: #666;
  margin-top: 2px;
}

/* Améliorer le style des cellules pour le drop */
.day-cell.drag-over {
  background-color: rgba(123, 31, 162, 0.1);
}

.resource-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-row,
.info-timeline {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(248, 249, 250, 0.95); /* Légère transparence */
}

/* Assurer que les cellules sous les éléments sticky ne transparaissent pas */
.resource-row:not(.info-timeline) {
  position: relative;
  z-index: 1;
}

/* Ajuster la hauteur du contenu scrollable */
.resources-grid,
.resource-names {
  height: calc(100vh - 170px);
}

/* Améliorer la transition visuelle */
.info-row,
.info-timeline {
  transition: box-shadow 0.3s ease;
}

.info-row.is-sticky,
.info-timeline.is-sticky {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.3s ease-in-out;
}

.dialog-bottom-transition-enter,
.dialog-bottom-transition-leave-to {
  transform: translateY(100%);
}

.mini-stats-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 8px;
  width: 100%;
  background-color: #fff;
}

.mini-stat {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  color: #666;
  width: 100%;
  padding-left: Opx;
}

.stat-text {
  margin-left: 0px;
}

.open-btn {
  margin-left: 0px;
  margin-top: 4px;
}
.stat-row {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.warning--text {
  color: #fb8c00;
}

.menu-mini {
  padding: 8px 0;
}

.mini-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
  padding-left: 0;
  /* margin-left: 13px; */
  position: absolute;
  left: 10px;
  top: 60px;
}

.mini-buttons .v-btn {
  margin-left: 4px;
}

/* Style pour l'épingle au survol */
.pin-button {
  position: absolute;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease, background-color 0.2s ease;
}

.pin-button.show-on-hover {
  opacity: 0;
}

/* Afficher l'épingle au survol de la ressource */
.resource-name:hover .pin-button.show-on-hover {
  opacity: 1;
}

/* Toujours afficher l'épingle si la ressource est épinglée */
.pin-button.pinned {
  opacity: 1;
  color: #7b1fa2;
}

/* Style pour la ressource épinglée */
.resource-name.pinned {
  background-color: rgba(123, 31, 162, 0.05);
}

.resource-name.pinned .pin-button {
  opacity: 1;
}

/* Ajustements pour le mode mini */
.ressource-mini {
  padding: 10px 8px;
  width: 30%;
}

.ressource-mini .pin-button {
  right: 5px;
}

.ressource-mini .resource-content {
  justify-content: center;
}

/* Animation pour l'apparition/disparition de l'épingle */
.pin-button {
  transform: scale(1);
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.pin-button:hover {
  transform: scale(1.1);
}

/* Style du bouton au survol */
.pin-button:hover {
  background-color: rgba(123, 31, 162, 0.1);
  border-radius: 50%;
}

.pin-custom {
  /* rotate */
  transform: rotate(45deg);
}

.zoom-controls {
  display: flex;
  gap: 8px;
  margin-top: 26px;
  /*  */
  /* padding: 0 12px; */
}

.zoom-slider {
  width: 100px;
  margin: 0;
}

.resource-row {
  transition: height 0.2s ease, min-height 0.2s ease;
}

.unassigned-tasks-container {
  background-color: #fff5f5;
  transition: all 0.3s ease;
}

.unassigned-tasks-container.unassigned-drag-over {
  background-color: rgba(123, 31, 162, 0.1);
  border: 2px dashed #673ab7;
}

.tasks-container {
  max-height: 130px;
  overflow-y: auto;
  padding-bottom: 6px;
}

.unassigned-task {
  background-color: #fee2e2;
  padding: 8px;
  margin: 4px 0;
  border-radius: 4px;
  cursor: grab;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
}

.unassigned-task:hover {
  background-color: #fecaca;
  transform: translateY(-1px);
}

.unassigned-task:active {
  cursor: grabbing;
}

.task-title {
  /* font-size: 0.9rem; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.task-duration {
  font-size: 0.8rem;
  color: #666;
  margin-left: 8px;
  white-space: nowrap;
}

/* Ajout d'une indication visuelle pour le drag & drop */
.unassigned-tasks-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(123, 31, 162, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.unassigned-tasks-container.unassigned-drag-over::before {
  opacity: 1;
}

/* Désactiver spécifiquement les cellules de la ligne des tâches sans date prévisionnelle */
.unassigned-tasks-container .day-cell {
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: #f8f9fa !important;
  opacity: 0.8;
}

/* Garder le style visuel même au survol */
.unassigned-tasks-container .day-cell:hover {
  background-color: #f8f9fa !important;
}
</style>
